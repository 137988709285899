/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SettingsAction } from './SettingsAction';
import {
    SettingsActionFromJSON,
    SettingsActionFromJSONTyped,
    SettingsActionToJSON,
    SettingsActionToJSONTyped,
} from './SettingsAction';
import type { SettingsCondition } from './SettingsCondition';
import {
    SettingsConditionFromJSON,
    SettingsConditionFromJSONTyped,
    SettingsConditionToJSON,
    SettingsConditionToJSONTyped,
} from './SettingsCondition';
import type { SettingsOptions } from './SettingsOptions';
import {
    SettingsOptionsFromJSON,
    SettingsOptionsFromJSONTyped,
    SettingsOptionsToJSON,
    SettingsOptionsToJSONTyped,
} from './SettingsOptions';
import type { SettingsButton } from './SettingsButton';
import {
    SettingsButtonFromJSON,
    SettingsButtonFromJSONTyped,
    SettingsButtonToJSON,
    SettingsButtonToJSONTyped,
} from './SettingsButton';
import type { SettingsDomain } from './SettingsDomain';
import {
    SettingsDomainFromJSON,
    SettingsDomainFromJSONTyped,
    SettingsDomainToJSON,
    SettingsDomainToJSONTyped,
} from './SettingsDomain';

/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    userId: string;
    /**
     * 
     * @type {Array<SettingsDomain>}
     * @memberof Settings
     */
    domains: Array<SettingsDomain>;
    /**
     * 
     * @type {Array<SettingsButton>}
     * @memberof Settings
     */
    buttons: Array<SettingsButton>;
    /**
     * 
     * @type {Array<SettingsAction>}
     * @memberof Settings
     */
    actions: Array<SettingsAction>;
    /**
     * 
     * @type {Array<SettingsCondition>}
     * @memberof Settings
     */
    conditions: Array<SettingsCondition>;
    /**
     * 
     * @type {SettingsOptions}
     * @memberof Settings
     */
    options: SettingsOptions;
}

/**
 * Check if a given object implements the Settings interface.
 */
export function instanceOfSettings(value: object): value is Settings {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('domains' in value) || value['domains'] === undefined) return false;
    if (!('buttons' in value) || value['buttons'] === undefined) return false;
    if (!('actions' in value) || value['actions'] === undefined) return false;
    if (!('conditions' in value) || value['conditions'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function SettingsFromJSON(json: any): Settings {
    return SettingsFromJSONTyped(json, false);
}

export function SettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Settings {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'domains': ((json['domains'] as Array<any>).map(SettingsDomainFromJSON)),
        'buttons': ((json['buttons'] as Array<any>).map(SettingsButtonFromJSON)),
        'actions': ((json['actions'] as Array<any>).map(SettingsActionFromJSON)),
        'conditions': ((json['conditions'] as Array<any>).map(SettingsConditionFromJSON)),
        'options': SettingsOptionsFromJSON(json['options']),
    };
}

  export function SettingsToJSON(json: any): Settings {
      return SettingsToJSONTyped(json, false);
  }

  export function SettingsToJSONTyped(value?: Settings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userId': value['userId'],
        'domains': ((value['domains'] as Array<any>).map(SettingsDomainToJSON)),
        'buttons': ((value['buttons'] as Array<any>).map(SettingsButtonToJSON)),
        'actions': ((value['actions'] as Array<any>).map(SettingsActionToJSON)),
        'conditions': ((value['conditions'] as Array<any>).map(SettingsConditionToJSON)),
        'options': SettingsOptionsToJSON(value['options']),
    };
}

