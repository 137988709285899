/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  LoginResponse,
  PasswordResetResponse,
  PasswordResetSuccessResponse,
  User,
  UserCreateRequest,
  UserModifiedResponse,
  UserUpdateRequest,
  WordPressSignupCreateRequest,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    PasswordResetResponseFromJSON,
    PasswordResetResponseToJSON,
    PasswordResetSuccessResponseFromJSON,
    PasswordResetSuccessResponseToJSON,
    UserFromJSON,
    UserToJSON,
    UserCreateRequestFromJSON,
    UserCreateRequestToJSON,
    UserModifiedResponseFromJSON,
    UserModifiedResponseToJSON,
    UserUpdateRequestFromJSON,
    UserUpdateRequestToJSON,
    WordPressSignupCreateRequestFromJSON,
    WordPressSignupCreateRequestToJSON,
} from '../models/index';

export interface UserApiCreateUserRequest {
    userCreateRequest: UserCreateRequest;
}

export interface UserApiLoginRequest {
    email: string;
    password: string;
    remember?: boolean;
}

export interface UserApiRequestPasswordResetRequest {
    emailAddress: string;
}

export interface UserApiResetPasswordRequest {
    token: string;
    newPassword: string;
}

export interface UserApiSetStorageSettingsRequest {
    implementation: SetStorageSettingsImplementationEnum;
}

export interface UserApiUpdatePasswordRequest {
    currentPassword: string;
    newPassword: string;
}

export interface UserApiUpdateUserRequest {
    userUpdateRequest: UserUpdateRequest;
}

export interface UserApiWordPressSignupRequest {
    wordPressSignupCreateRequest: WordPressSignupCreateRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Create a new user from the provided properties. If no password is provided, one will be created internally. This can then be reset via a \'reset password\' request.
     * Create a new user.
     */
    async createUserRaw(requestParameters: UserApiCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['userCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'userCreateRequest',
                'Required parameter "userCreateRequest" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateRequestToJSON(requestParameters['userCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Create a new user from the provided properties. If no password is provided, one will be created internally. This can then be reset via a \'reset password\' request.
     * Create a new user.
     */
    async createUser(requestParameters: UserApiCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the currently logged in `User
     */
    async deleteUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserModifiedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModifiedResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the currently logged in `User
     */
    async deleteUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserModifiedResponse> {
        const response = await this.deleteUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the email opt-in to true for this `User`
     */
    async enableEmailOptInPreferenceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserModifiedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user/emailPreference`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModifiedResponseFromJSON(jsonValue));
    }

    /**
     * Set the email opt-in to true for this `User`
     */
    async enableEmailOptInPreference(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserModifiedResponse> {
        const response = await this.enableEmailOptInPreferenceRaw(initOverrides);
        return await response.value();
    }

    /**
     * The user has to be logged in. The user can also be a chat agent (meaning it does not have `ROLE_USER`, but only a `ROLE_CHAT_AGENT`).
     * Retrieve the current (logged in) user.
     */
    async getUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * The user has to be logged in. The user can also be a chat agent (meaning it does not have `ROLE_USER`, but only a `ROLE_CHAT_AGENT`).
     * Retrieve the current (logged in) user.
     */
    async getUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Login the current user via a form.
     */
    async loginRaw(requestParameters: UserApiLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling login().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling login().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['remember'] != null) {
            queryParameters['remember'] = requestParameters['remember'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login the current user via a form.
     */
    async login(requestParameters: UserApiLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout.
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Logout.
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.logoutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set the email opt-in to false for this `User`
     */
    async removeEmailOptInPreferenceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserModifiedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user/emailPreference`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModifiedResponseFromJSON(jsonValue));
    }

    /**
     * Set the email opt-in to false for this `User`
     */
    async removeEmailOptInPreference(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserModifiedResponse> {
        const response = await this.removeEmailOptInPreferenceRaw(initOverrides);
        return await response.value();
    }

    /**
     * Allows a `User` to request a (short-lived) token. This will be send via e-mail.
     */
    async requestPasswordResetRaw(requestParameters: UserApiRequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordResetResponse>> {
        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling requestPasswordReset().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/password-reset`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetResponseFromJSON(jsonValue));
    }

    /**
     * Allows a `User` to request a (short-lived) token. This will be send via e-mail.
     */
    async requestPasswordReset(requestParameters: UserApiRequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordResetResponse> {
        const response = await this.requestPasswordResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allows a `User` to reset their password via a (short-lived) token.
     */
    async resetPasswordRaw(requestParameters: UserApiResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordResetSuccessResponse>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling resetPassword().'
            );
        }

        if (requestParameters['newPassword'] == null) {
            throw new runtime.RequiredError(
                'newPassword',
                'Required parameter "newPassword" was null or undefined when calling resetPassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        if (requestParameters['newPassword'] != null) {
            queryParameters['newPassword'] = requestParameters['newPassword'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user/password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetSuccessResponseFromJSON(jsonValue));
    }

    /**
     * Allows a `User` to reset their password via a (short-lived) token.
     */
    async resetPassword(requestParameters: UserApiResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordResetSuccessResponse> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Switch the user storage platform to a different one (either GCP (Google) or R2 (Cloudflare).
     */
    async setStorageSettingsRaw(requestParameters: UserApiSetStorageSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['implementation'] == null) {
            throw new runtime.RequiredError(
                'implementation',
                'Required parameter "implementation" was null or undefined when calling setStorageSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user/settings/storage/{implementation}`.replace(`{${"implementation"}}`, encodeURIComponent(String(requestParameters['implementation']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Switch the user storage platform to a different one (either GCP (Google) or R2 (Cloudflare).
     */
    async setStorageSettings(requestParameters: UserApiSetStorageSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.setStorageSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the password for a logged in `User
     */
    async updatePasswordRaw(requestParameters: UserApiUpdatePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserModifiedResponse>> {
        if (requestParameters['currentPassword'] == null) {
            throw new runtime.RequiredError(
                'currentPassword',
                'Required parameter "currentPassword" was null or undefined when calling updatePassword().'
            );
        }

        if (requestParameters['newPassword'] == null) {
            throw new runtime.RequiredError(
                'newPassword',
                'Required parameter "newPassword" was null or undefined when calling updatePassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['currentPassword'] != null) {
            queryParameters['currentPassword'] = requestParameters['currentPassword'];
        }

        if (requestParameters['newPassword'] != null) {
            queryParameters['newPassword'] = requestParameters['newPassword'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user/update-password`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserModifiedResponseFromJSON(jsonValue));
    }

    /**
     * Update the password for a logged in `User
     */
    async updatePassword(requestParameters: UserApiUpdatePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserModifiedResponse> {
        const response = await this.updatePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a user. Currently only `name`, `companyName` and `phone` can be updated.
     */
    async updateUserRaw(requestParameters: UserApiUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['userUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'userUpdateRequest',
                'Required parameter "userUpdateRequest" was null or undefined when calling updateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/user`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateRequestToJSON(requestParameters['userUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update a user. Currently only `name`, `companyName` and `phone` can be updated.
     */
    async updateUser(requestParameters: UserApiUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a `User` from a WordPress signup.
     */
    async wordPressSignupRaw(requestParameters: UserApiWordPressSignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WordPressSignupCreateRequest>> {
        if (requestParameters['wordPressSignupCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'wordPressSignupCreateRequest',
                'Required parameter "wordPressSignupCreateRequest" was null or undefined when calling wordPressSignup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user/wp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WordPressSignupCreateRequestToJSON(requestParameters['wordPressSignupCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WordPressSignupCreateRequestFromJSON(jsonValue));
    }

    /**
     * Create a `User` from a WordPress signup.
     */
    async wordPressSignup(requestParameters: UserApiWordPressSignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WordPressSignupCreateRequest> {
        const response = await this.wordPressSignupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const SetStorageSettingsImplementationEnum = {
    Gcs: 'GCS',
    R2: 'R2'
} as const;
export type SetStorageSettingsImplementationEnum = typeof SetStorageSettingsImplementationEnum[keyof typeof SetStorageSettingsImplementationEnum];
