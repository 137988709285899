import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import React from "react";
import { ExpansionPanel, ExtendedFormState } from "../../common";
import ColorPicker from "./ColorPicker";
import { Action, Button } from "../../lib/cnb/api/public";

const ActionPresentation: React.FC<{
  formal: ExtendedFormState<Action>;
  buttonType: Button["type"];
}> = ({ formal, buttonType }) => {
  const hasError = formal.errors.backgroundColor || formal.errors.iconColor;
  const isFullWidthButton = buttonType === "FULL";

  return (
    <ExpansionPanel
      title="Presentation Settings"
      titleColor={hasError ? "error" : "initial"}
    >
      <FormGroup>
        <ColorPicker
          label="Button color"
          {...formal.getTextFieldProps("backgroundColor")}
        />
        <ColorPicker
          label={isFullWidthButton ? "Icon & text color" : "Icon color"}
          {...formal.getTextFieldProps("iconColor")}
        />
        {isFullWidthButton && (
          <FormControlLabel
            control={
              <Switch
                {...formal.getCheckboxProps("iconEnabled")}
                color="primary"
              />
            }
            label="Show icon"
          />
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default ActionPresentation;
