/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserToJSONTyped,
} from './User';
import type { Button } from './Button';
import {
    ButtonFromJSON,
    ButtonFromJSONTyped,
    ButtonToJSON,
    ButtonToJSONTyped,
} from './Button';
import type { PromotionCode } from './PromotionCode';
import {
    PromotionCodeFromJSON,
    PromotionCodeFromJSONTyped,
    PromotionCodeToJSON,
    PromotionCodeToJSONTyped,
} from './PromotionCode';
import type { SettingsOptions } from './SettingsOptions';
import {
    SettingsOptionsFromJSON,
    SettingsOptionsFromJSONTyped,
    SettingsOptionsToJSON,
    SettingsOptionsToJSONTyped,
} from './SettingsOptions';
import type { StripeAgencyPlan } from './StripeAgencyPlan';
import {
    StripeAgencyPlanFromJSON,
    StripeAgencyPlanFromJSONTyped,
    StripeAgencyPlanToJSON,
    StripeAgencyPlanToJSONTyped,
} from './StripeAgencyPlan';
import type { SubscriptionStatusData } from './SubscriptionStatusData';
import {
    SubscriptionStatusDataFromJSON,
    SubscriptionStatusDataFromJSONTyped,
    SubscriptionStatusDataToJSON,
    SubscriptionStatusDataToJSONTyped,
} from './SubscriptionStatusData';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
    DomainToJSONTyped,
} from './Domain';
import type { StripePlan } from './StripePlan';
import {
    StripePlanFromJSON,
    StripePlanFromJSONTyped,
    StripePlanToJSON,
    StripePlanToJSONTyped,
} from './StripePlan';
import type { ValidationResult } from './ValidationResult';
import {
    ValidationResultFromJSON,
    ValidationResultFromJSONTyped,
    ValidationResultToJSON,
    ValidationResultToJSONTyped,
} from './ValidationResult';

/**
 * 
 * @export
 * @interface WordPressInfo
 */
export interface WordPressInfo {
    /**
     * 
     * @type {Domain}
     * @memberof WordPressInfo
     */
    currentDomain?: Domain;
    /**
     * 
     * @type {Array<Domain>}
     * @memberof WordPressInfo
     */
    domains: Array<Domain>;
    /**
     * 
     * @type {Array<Button>}
     * @memberof WordPressInfo
     */
    buttons: Array<Button>;
    /**
     * 
     * @type {Array<ValidationResult>}
     * @memberof WordPressInfo
     */
    validationMessages: Array<ValidationResult>;
    /**
     * 
     * @type {User}
     * @memberof WordPressInfo
     */
    user: User;
    /**
     * 
     * @type {PromotionCode}
     * @memberof WordPressInfo
     */
    coupon?: PromotionCode;
    /**
     * 
     * @type {Array<StripePlan>}
     * @memberof WordPressInfo
     */
    plans: Array<StripePlan>;
    /**
     * 
     * @type {Array<StripeAgencyPlan>}
     * @memberof WordPressInfo
     */
    agencyPlans: Array<StripeAgencyPlan>;
    /**
     * 
     * @type {SubscriptionStatusData}
     * @memberof WordPressInfo
     */
    subscriptionStatusData?: SubscriptionStatusData;
    /**
     * 
     * @type {SettingsOptions}
     * @memberof WordPressInfo
     */
    settings: SettingsOptions;
}

/**
 * Check if a given object implements the WordPressInfo interface.
 */
export function instanceOfWordPressInfo(value: object): value is WordPressInfo {
    if (!('domains' in value) || value['domains'] === undefined) return false;
    if (!('buttons' in value) || value['buttons'] === undefined) return false;
    if (!('validationMessages' in value) || value['validationMessages'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('plans' in value) || value['plans'] === undefined) return false;
    if (!('agencyPlans' in value) || value['agencyPlans'] === undefined) return false;
    if (!('settings' in value) || value['settings'] === undefined) return false;
    return true;
}

export function WordPressInfoFromJSON(json: any): WordPressInfo {
    return WordPressInfoFromJSONTyped(json, false);
}

export function WordPressInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordPressInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'currentDomain': json['currentDomain'] == null ? undefined : DomainFromJSON(json['currentDomain']),
        'domains': ((json['domains'] as Array<any>).map(DomainFromJSON)),
        'buttons': ((json['buttons'] as Array<any>).map(ButtonFromJSON)),
        'validationMessages': ((json['validationMessages'] as Array<any>).map(ValidationResultFromJSON)),
        'user': UserFromJSON(json['user']),
        'coupon': json['coupon'] == null ? undefined : PromotionCodeFromJSON(json['coupon']),
        'plans': ((json['plans'] as Array<any>).map(StripePlanFromJSON)),
        'agencyPlans': ((json['agencyPlans'] as Array<any>).map(StripeAgencyPlanFromJSON)),
        'subscriptionStatusData': json['subscriptionStatusData'] == null ? undefined : SubscriptionStatusDataFromJSON(json['subscriptionStatusData']),
        'settings': SettingsOptionsFromJSON(json['settings']),
    };
}

  export function WordPressInfoToJSON(json: any): WordPressInfo {
      return WordPressInfoToJSONTyped(json, false);
  }

  export function WordPressInfoToJSONTyped(value?: WordPressInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'currentDomain': DomainToJSON(value['currentDomain']),
        'domains': ((value['domains'] as Array<any>).map(DomainToJSON)),
        'buttons': ((value['buttons'] as Array<any>).map(ButtonToJSON)),
        'validationMessages': ((value['validationMessages'] as Array<any>).map(ValidationResultToJSON)),
        'user': UserToJSON(value['user']),
        'coupon': PromotionCodeToJSON(value['coupon']),
        'plans': ((value['plans'] as Array<any>).map(StripePlanToJSON)),
        'agencyPlans': ((value['agencyPlans'] as Array<any>).map(StripeAgencyPlanToJSON)),
        'subscriptionStatusData': SubscriptionStatusDataToJSON(value['subscriptionStatusData']),
        'settings': SettingsOptionsToJSON(value['settings']),
    };
}

