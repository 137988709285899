import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";

const SmsSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  return (
    <ExpansionPanel title={"SMS Settings"}>
      <FormGroup>
        <TextField
          label="Default message"
          {...formal.getTextFieldProps(`${pathPrefix}properties.message`)}
          helperText="This helps visitors to formulate their questions to you"
        />
      </FormGroup>
    </ExpansionPanel>
  );
};

export default SmsSettings;
