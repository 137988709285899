import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import { AccountSettings } from "../auth";
import {
  routes,
  Page,
  RouteDrawer,
  usePopInitialPath,
  SpinnerContext,
} from "../common";
import { useDomain, DomainContext, DomainSettings, NewDomain } from "../domain";
import ButtonSettings from "./ButtonSettings";
import { ButtonList, Header } from "./components";
import { useButton, ButtonContext } from "./useButton";
import { CodeSnippet } from "../common";

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingBottom: theme.spacing(6),
  },
  topPadding: theme.mixins.toolbar as any,
  startContent: {
    justifyContent: "center",
    height: "100%",
  },
  addNewButton: {
    zIndex: theme.zIndex.speedDial,
    position: "fixed",
    bottom: theme.spacing(2),
    left: 400 - 56 - parseInt(theme.spacing(2)),
    [theme.breakpoints.down("sm")]: {
      left: "auto",
      right: theme.spacing(2),
    },
  },
}));

const DashboardPage: React.FC = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const domainContextValue = useDomain(history.location.search);
  const buttonContextValue = useButton();
  const dataLoaded =
    !!domainContextValue.stripePlans &&
    !!domainContextValue.domains &&
    !!buttonContextValue.buttons;
  const initialPath = usePopInitialPath(routes.DASHBOARD.path, dataLoaded);
  const hasCurrentDomainButtons = Object.values(
    buttonContextValue.buttons || {},
  ).some((button) => button.domain.id === domainContextValue.currentDomain?.id);

  const { showSpinner } = React.useContext(SpinnerContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => showSpinner(!dataLoaded), [dataLoaded]);

  return (
    <DomainContext.Provider value={domainContextValue}>
      <ButtonContext.Provider value={buttonContextValue}>
        <Header />
        <Page className={classes.root}>
          <div className={classes.topPadding} />
          {!!domainContextValue.currentDomain && (
            <Fab
              color="primary"
              className={classes.addNewButton}
              title="Create new button"
              component={RouterLink}
              to={routes.BUTTON_NEW.path}
            >
              <AddIcon fontSize="large" />
            </Fab>
          )}
          {!dataLoaded ? null : hasCurrentDomainButtons ? (
            <ButtonList />
          ) : domainContextValue.currentDomain ? (
            <FormGroup className={classes.startContent}>
              <Typography variant="subtitle1" paragraph align="center">
                Domain added successfully!
              </Typography>
              <Typography variant="body2" paragraph align="center">
                To install the button, copy the line of JavaScript below and
                paste it before the closing tag on every page you want a button
                to appear.
              </Typography>
              <Typography variant="body2" component={"span"}>
                <CodeSnippet
                  snackbarOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  {`<script async src="${domainContextValue.getClientUrl(
                    domainContextValue.currentDomain.id,
                  )}"></script>`}
                </CodeSnippet>
              </Typography>
              <Typography variant="body2" paragraph align="center">
                For more detailed instructions or an email template to send to
                your developer, please visit our{" "}
                <Link href="https://nowbuttons.com/support/web-app/implementation/installing-on-single-website/">
                  help center
                </Link>
                .
              </Typography>
            </FormGroup>
          ) : (
            <FormGroup className={classes.startContent}>
              <Typography paragraph align="center">
                Let's get started by adding a domain to your account.
              </Typography>
              <Button
                color="primary"
                component={RouterLink}
                to={routes.DOMAIN_NEW.path}
              >
                <AddIcon />
                Add domain
              </Button>
            </FormGroup>
          )}
          {initialPath && <Redirect push to={initialPath} />}
          <RouteDrawer {...routes.PROFILE} drawerProps={{ anchor: "right" }}>
            <AccountSettings />
          </RouteDrawer>
          <RouteDrawer
            {...routes.DOMAIN_EDIT}
            drawerProps={{ anchor: "right" }}
          >
            <DomainSettings />
          </RouteDrawer>
          <RouteDrawer {...routes.DOMAIN_NEW} drawerProps={{ anchor: "right" }}>
            <NewDomain />
          </RouteDrawer>
          <RouteDrawer {...routes.BUTTON_NEW} drawerProps={{ anchor: "left" }}>
            <ButtonSettings />
          </RouteDrawer>
          <RouteDrawer {...routes.BUTTON_EDIT} drawerProps={{ anchor: "left" }}>
            <ButtonSettings />
          </RouteDrawer>
        </Page>
      </ButtonContext.Provider>
    </DomainContext.Provider>
  );
};

export default DashboardPage;
