import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import React from "react";
import * as yup from "yup";
import { useExtendedForm, withFormReset } from "../../common";
import { AuthContext } from "../useAuth";

const initialValues = {
  password: "",
  password2: "",
  oldPassword: "",
};

const schema = yup
  .object<typeof initialValues>()
  .shape({
    password: yup
      .string()
      .min(9, "Passwords should be at least 9 characters.")
      .required(),
    password2: yup
      .string()
      .oneOf([yup.ref("password"), ""], "passwords don't match")
      .required("confirm password is missing"),
    oldPassword: yup.string().required("the old password is needed"),
  })
  .defined();

const PasswordChangeForm: React.FC<{ reset: () => void }> = ({ reset }) => {
  const { updatePassword } = React.useContext(AuthContext);
  const formal = useExtendedForm({
    initialValues,
    schema,
    onSubmit: async (values) => {
      await updatePassword(values.oldPassword, values.password);
      reset();
    },
  });

  return (
    <form {...formal.getFormProps()}>
      <FormGroup>
        <TextField
          label="Current password"
          type="password"
          {...formal.getTextFieldProps("oldPassword")}
        />
        <TextField
          label="New password"
          type="password"
          {...formal.getTextFieldProps("password")}
        />
        <TextField
          label="Repeat new password"
          type="password"
          {...formal.getTextFieldProps("password2")}
        />
      </FormGroup>
      <Button
        variant="contained"
        color="primary"
        {...formal.getSubmitButtonProps()}
        type="submit"
      >
        Save
      </Button>
      {formal.submitState.state === "error" && (
        <FormHelperText error>
          {formal.submitState.message ||
            "Server error: couldn't update password"}
        </FormHelperText>
      )}
    </form>
  );
};

export default withFormReset(PasswordChangeForm);
