/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProAccountCheckoutSessionCreateRequest
 */
export interface ProAccountCheckoutSessionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProAccountCheckoutSessionCreateRequest
     */
    plan: string;
    /**
     * 
     * @type {string}
     * @memberof ProAccountCheckoutSessionCreateRequest
     */
    callbackUri: string;
    /**
     * 
     * @type {string}
     * @memberof ProAccountCheckoutSessionCreateRequest
     */
    uiMode?: ProAccountCheckoutSessionCreateRequestUiModeEnum;
    /**
     * Defaults to the User's currency if set
     * @type {string}
     * @memberof ProAccountCheckoutSessionCreateRequest
     */
    currency?: string;
}


/**
 * @export
 */
export const ProAccountCheckoutSessionCreateRequestUiModeEnum = {
    Embedded: 'EMBEDDED',
    Hosted: 'HOSTED'
} as const;
export type ProAccountCheckoutSessionCreateRequestUiModeEnum = typeof ProAccountCheckoutSessionCreateRequestUiModeEnum[keyof typeof ProAccountCheckoutSessionCreateRequestUiModeEnum];


/**
 * Check if a given object implements the ProAccountCheckoutSessionCreateRequest interface.
 */
export function instanceOfProAccountCheckoutSessionCreateRequest(value: object): value is ProAccountCheckoutSessionCreateRequest {
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('callbackUri' in value) || value['callbackUri'] === undefined) return false;
    return true;
}

export function ProAccountCheckoutSessionCreateRequestFromJSON(json: any): ProAccountCheckoutSessionCreateRequest {
    return ProAccountCheckoutSessionCreateRequestFromJSONTyped(json, false);
}

export function ProAccountCheckoutSessionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProAccountCheckoutSessionCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'plan': json['plan'],
        'callbackUri': json['callbackUri'],
        'uiMode': json['uiMode'] == null ? undefined : json['uiMode'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

  export function ProAccountCheckoutSessionCreateRequestToJSON(json: any): ProAccountCheckoutSessionCreateRequest {
      return ProAccountCheckoutSessionCreateRequestToJSONTyped(json, false);
  }

  export function ProAccountCheckoutSessionCreateRequestToJSONTyped(value?: ProAccountCheckoutSessionCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'plan': value['plan'],
        'callbackUri': value['callbackUri'],
        'uiMode': value['uiMode'],
        'currency': value['currency'],
    };
}

