import {
  Apikey,
  ApikeyWithKey,
  SetStorageSettingsImplementationEnum,
  User,
} from "../lib/cnb/api/public";
import { apikeyApi, stripeApi, userApi } from "../cnb/apis";

const api = {
  getCurrentUser: async (): Promise<User | null> => {
    return userApi.getUser();
  },

  register: async (
    email: string,
    password: string,
    emailOptIn: boolean,
    signupSource: string,
  ) => {
    await userApi.createUser({
      userCreateRequest: { email, password, emailOptIn, signupSource },
    });
  },

  login: async (
    email: string,
    password: string,
    remember: boolean,
  ): Promise<User | null> => {
    await userApi.login({ email, password, remember });
    return userApi.getUser();
  },

  logout: async () => {
    await userApi.logout();
    return null;
  },

  updateUser: async (user: Partial<User>): Promise<User> => {
    return userApi.updateUser({ userUpdateRequest: { ...user } });
  },

  updatePassword: async (currentPassword: string, newPassword: string) => {
    await userApi.updatePassword({ newPassword, currentPassword });
  },

  requestPasswordReset: async (emailAddress: string) => {
    await userApi.requestPasswordReset({ emailAddress });
  },

  resetPassword: async (token: string, newPassword: string) => {
    await userApi.resetPassword({ token, newPassword });
  },

  getApikeys: async (): Promise<Apikey[]> => apikeyApi.getAll(),

  createApikey: async (name: string): Promise<ApikeyWithKey> =>
    apikeyApi.create({ apikeyCreateRequest: { name } }),

  deleteApikey: async (id: string) => {
    await apikeyApi.delete({ apikeyId: id });
  },

  openPaymentHistory: async () => {
    const { url } = await stripeApi.createBillingPortal();
    window.open(url);
  },

  setStorageImplementation: async (
    storageImplementation: SetStorageSettingsImplementationEnum,
  ) => {
    await userApi.setStorageSettings({ implementation: storageImplementation });
  },
};

export default api;

const demoLogin = (email: string, password: string) => {
  const user: User = {
    name: "Demo User",
    marketingData: {
      emailOptIn: false,
      signupSource: "foobar",
    },
    ...JSON.parse(localStorage.getItem("usertoken") || "{}"),
    id: String(Math.floor(Math.random() * 1000)),
    email,
    password,
  };
  localStorage.setItem("usertoken", JSON.stringify(user));
  return Promise.resolve(user);
};

const demoGetApikeys = <K>(): K[] =>
  JSON.parse(localStorage.getItem("apikeys") || "[]");

const demoSetApikeys = (apikeys: unknown[]) => {
  localStorage.setItem("apikeys", JSON.stringify(apikeys));
};

export const demoApi: typeof api = {
  getCurrentUser: (): Promise<User | null> => {
    const user = JSON.parse(localStorage.getItem("usertoken") || "null");
    return Promise.resolve(user);
  },

  register: () => Promise.resolve(),

  login: (email: string, password: string, remember: boolean) => {
    if (!remember) {
      setTimeout(() => localStorage.removeItem("usertoken"), 10 * 1000);
    }
    return demoLogin(email, password);
  },

  logout: () => {
    localStorage.removeItem("usertoken");
    return Promise.resolve(null);
  },

  updateUser: (user: Partial<User>) => {
    const updatedUser = {
      ...JSON.parse(localStorage.getItem("usertoken") || "{}"),
      ...user,
    };
    localStorage.setItem("usertoken", JSON.stringify(updatedUser));
    return Promise.resolve(updatedUser);
  },

  updatePassword: (currentPassword: string, newPassword: string) => {
    const user = JSON.parse(localStorage.getItem("usertoken") || "{}");
    if (user.password !== currentPassword) {
      return Promise.reject(new Error("Current password incorrect"));
    }
    localStorage.setItem(
      "usertoken",
      JSON.stringify({
        ...user,
        password: newPassword,
      }),
    );
    return Promise.resolve();
  },

  requestPasswordReset: () => Promise.resolve(),

  resetPassword: () => Promise.resolve(),

  getApikeys: <K>() =>
    Promise.resolve(
      demoGetApikeys<K>().map((k) => ({
        ...k,
        key: null,
      })),
    ),

  createApikey: (name: string) => {
    const apikeys = demoGetApikeys<any>();
    apikeys.push({
      id: String(Date.now()),
      name,
      key: String(Date.now()),
    });
    demoSetApikeys(apikeys);
    return Promise.resolve(apikeys[apikeys.length - 1]);
  },

  deleteApikey: (id: string) => {
    const apikeys = demoGetApikeys<any>();
    demoSetApikeys(apikeys.filter((d) => d.id !== id));
    return Promise.resolve();
  },

  openPaymentHistory: () => {
    window.open("https://github.com");
    return Promise.resolve();
  },

  setStorageImplementation: () => {
    return Promise.resolve();
  },
};
