import { wordPressApi } from "../cnb/apis";
import { Domain, WordPressInfo } from "../lib/cnb/api/public";

export const api = {
  getWpInfo: async (domain: Domain): Promise<WordPressInfo> => {
    return await wordPressApi.getDomain({ domainName: domain.name });
  },
};

export const demoApi = {
  getWpInfo: async (domain: Domain): Promise<WordPressInfo> => {
    return {
      currentDomain: domain,
      domains: [domain],
    } as WordPressInfo;
  },
};
