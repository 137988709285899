import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../common";
import { DomainContext } from "../../domain";
import { makeStyles } from "tss-react/mui";
import { alpha } from "@mui/material/styles";

const useStyles = makeStyles()((theme) => ({
  headerButton: {
    color: alpha(theme.palette.primary.contrastText, 0.8),
    "&:hover": { color: theme.palette.primary.contrastText },
    borderColor: "currentColor",
  },
  desktopOnly: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

interface UpgradeButtonProps {
  variant?: "text" | "outlined" | "contained";
}
const UpgradeButton = ({ variant = "outlined" }: UpgradeButtonProps) => {
  const { currentDomain, wpInfo } = useContext(DomainContext);
  const { classes, cx } = useStyles();

  const isUpgrade =
    currentDomain?.type !== "PRO" && !wpInfo?.hasOutstandingInvoice();

  if (!isUpgrade) return <></>;
  return (
    <Button
      variant={variant}
      className={cx(classes.headerButton, classes.desktopOnly)}
      component={RouterLink}
      to={routes.DOMAIN_PLAN.path}
    >
      Upgrade
    </Button>
  );
};

export default UpgradeButton;
