import {
  ActionApi,
  ApikeyApi,
  ButtonApi,
  ConditionApi,
  DomainApi,
  SettingsApi,
  StripeApi,
  SubscriptionApi,
  UserApi,
  WordPressApi,
} from "../lib/cnb/api/public";
import { apiConfig } from "./apiConfig";

export const userApi = new UserApi(apiConfig);
export const apikeyApi = new ApikeyApi(apiConfig);
export const stripeApi = new StripeApi(apiConfig);
export const subscriptionApi = new SubscriptionApi(apiConfig);
export const wordPressApi = new WordPressApi(apiConfig);
export const settingsApi = new SettingsApi(apiConfig);
export const domainApi = new DomainApi(apiConfig);
export const buttonApi = new ButtonApi(apiConfig);
export const actionApi = new ActionApi(apiConfig);
export const conditionApi = new ConditionApi(apiConfig);
