import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  routes,
  theme as cnbTheme,
  useInitialPath,
  GaWrapper,
  InitialPathContext,
  SpinnerWrapper,
} from "./common";
import {
  useAuth,
  AuthContext,
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from "./auth";
import { DashboardPage } from "./button";

const App: React.FC = () => {
  const initialPathContextValue = useInitialPath();
  const authContextValue = useAuth();
  return (
    <ThemeProvider theme={cnbTheme}>
      <CssBaseline />
      <SpinnerWrapper>
        <InitialPathContext.Provider value={initialPathContextValue}>
          <AuthContext.Provider value={authContextValue}>
            <BrowserRouter>
              <GaWrapper>
                <Switch>
                  {authContextValue.loggedIn ? (
                    <Redirect
                      from="/(login|register)"
                      to={routes.DASHBOARD.path}
                    />
                  ) : (
                    <Redirect from="/app" to={routes.LOGIN.path} />
                  )}
                  <Route path={routes.DASHBOARD.path}>
                    <DashboardPage />
                  </Route>
                  <Route path={routes.LOGIN.path}>
                    <LoginPage />
                  </Route>
                  <Route path={routes.REGISTER.path}>
                    <RegisterPage />
                  </Route>
                  <Route path={routes.FORGOT_PASSWORD.path}>
                    <ForgotPasswordPage />
                  </Route>
                  <Route path={routes.RESET_PASSWORD.path}>
                    <ResetPasswordPage />
                  </Route>
                  <Redirect to={routes.DASHBOARD.path} />
                </Switch>
              </GaWrapper>
            </BrowserRouter>
          </AuthContext.Provider>
        </InitialPathContext.Provider>
      </SpinnerWrapper>
    </ThemeProvider>
  );
};

export default App;
