import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";

const EmailSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  return (
    <ExpansionPanel title="Email Settings">
      <FormGroup>
        <TextField
          label="Subject line"
          {...formal.getTextFieldProps(`${pathPrefix}properties.subject`)}
        />
        <TextField
          label="Email body text"
          {...formal.getTextFieldProps(`${pathPrefix}properties.body`)}
        />
        <TextField
          label="CC"
          {...formal.getTextFieldProps(`${pathPrefix}properties.cc`)}
        />
        <TextField
          label="BCC"
          {...formal.getTextFieldProps(`${pathPrefix}properties.bcc`)}
        />
      </FormGroup>
    </ExpansionPanel>
  );
};

export default EmailSettings;
