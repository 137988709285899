import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Snackbar, SnackbarOrigin, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CopyIcon } from "../icons";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles()({
  preWrapper: {
    backgroundColor: grey[200],
    position: "relative",
    width: "100%",
    minHeight: "50px",
  },
  codeSnippet: {
    marginRight: 35,
  },
  toolTip: {
    position: "absolute",
    bottom: 0,
    right: "13px",
  },
  textField: {
    fontFamily: "monospace",
    wordBreak: "break-all",
    fontSize: "14px",
  },
});

interface CodeSnippetProps {
  /**
   * The snippet to be displayed (and put into the clipboard)
   */
  children?: string | null;
  /**
   * Label above the snippet. Only used if variant="textField".
   */
  label?: string;
  /**
   * Where to show the Snackbar after a Click event
   */
  snackbarOrigin?: SnackbarOrigin;
}

/**
 * Displays a small snippet of code, including the ability
 * to copy this content to a User's clipboard.
 *
 */
const CodeSnippet: React.FC<CodeSnippetProps> = ({
  children,
  label = "Code snippet",
  snackbarOrigin = { vertical: "bottom", horizontal: "right" },
}: CodeSnippetProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { classes } = useStyles();

  const handleClick = async () => {
    setSnackbarOpen(true);
    await navigator.clipboard.writeText(String(children));
  };

  const handleClose = (event: unknown, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <TextField
        multiline
        variant="filled"
        label={label}
        value={children}
        onClick={handleClick}
        onFocus={(event) => event.target.select()}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip disableFocusListener title="Copy snippet to clipboard">
                <IconButton edge="end" size="large">
                  <CopyIcon color="disabled" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          className: classes.textField,
        }}
      />
      <Snackbar
        anchorOrigin={snackbarOrigin}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Snippet copied to clipboard. Put snippet in the <head> of your website."
      />
    </>
  );
};

export default CodeSnippet;
