/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
    ActionToJSONTyped,
} from './Action';

/**
 * 
 * @export
 * @interface ActionDeleteResponse
 */
export interface ActionDeleteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ActionDeleteResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionDeleteResponse
     */
    id: string;
    /**
     * 
     * @type {Action}
     * @memberof ActionDeleteResponse
     */
    object: Action;
}

/**
 * Check if a given object implements the ActionDeleteResponse interface.
 */
export function instanceOfActionDeleteResponse(value: object): value is ActionDeleteResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('object' in value) || value['object'] === undefined) return false;
    return true;
}

export function ActionDeleteResponseFromJSON(json: any): ActionDeleteResponse {
    return ActionDeleteResponseFromJSONTyped(json, false);
}

export function ActionDeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionDeleteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'id': json['id'],
        'object': ActionFromJSON(json['object']),
    };
}

  export function ActionDeleteResponseToJSON(json: any): ActionDeleteResponse {
      return ActionDeleteResponseToJSONTyped(json, false);
  }

  export function ActionDeleteResponseToJSONTyped(value?: ActionDeleteResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'success': value['success'],
        'id': value['id'],
        'object': ActionToJSON(value['object']),
    };
}

