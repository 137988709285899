import { Action, Button, Condition, Domain } from "../lib/cnb/api/public";

/**
 * Maps which ButtonTypes are allowed on which Domain subscriptions types.
 *
 * Currently, MULTI is not allowed on the STARTER plan (FULL is allowed, but only a single Action).
 */
export const ButtonTypeRestrictions = {
  SINGLE: ["STARTER", "PRO", "FREE"],
  MULTI: ["PRO", "FREE"],
  FULL: ["STARTER", "PRO", "FREE"],
  DOTS: ["PRO"],
} as Record<Button["type"], Domain["type"][]>;

/**
 * Maps which ActionTypes are allowed on which Domain subscriptions types.
 */
export const ActionTypeRestrictions = {
  ANCHOR: ["STARTER", "PRO", "FREE"],
  CHAT: ["PRO"],
  EMAIL: ["STARTER", "PRO", "FREE"],
  FACEBOOK: ["STARTER", "PRO", "FREE"],
  IFRAME: ["PRO"],
  INTERCOM: ["PRO"],
  LINE: ["STARTER", "PRO", "FREE"],
  LINK: ["STARTER", "PRO", "FREE"],
  MAP: ["STARTER", "PRO", "FREE"],
  PHONE: ["STARTER", "PRO", "FREE"],
  SIGNAL: ["STARTER", "PRO", "FREE"],
  SKYPE: ["STARTER", "PRO", "FREE"],
  SMS: ["STARTER", "PRO", "FREE"],
  TALLY: ["PRO", "FREE"],
  TELEGRAM: ["STARTER", "PRO", "FREE"],
  VIBER: ["STARTER", "PRO", "FREE"],
  WECHAT: ["STARTER", "PRO", "FREE"],
  WHATSAPP: ["STARTER", "PRO", "FREE"],
  ZALO: ["STARTER", "PRO", "FREE"],
} as Record<Action["actionType"], Domain["type"][]>;

/**
 * Maps which ConditionTypes are allowed on which Domain subscriptions types.
 */
export const ConditionTypeRestrictions = {
  EXACT: ["STARTER", "PRO", "FREE"],
  SUBSTRING: ["STARTER", "PRO", "FREE"],
  SIMPLE: ["STARTER", "PRO", "FREE"],
  REGEX: ["PRO", "FREE"],
  COUNTRY_CODE: ["PRO"],
  AGENTS_AVAILABLE: ["PRO"],
} as Record<Condition["matchType"], Domain["type"][]>;
