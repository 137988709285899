import React from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { ExpansionPanel, ExtendedFormState } from "../../common";
import { ButtonType } from "../buttonModels";
import ColorPicker from "./ColorPicker";
import MultiButtonPresentation from "./MultiButtonPresentation";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "../../lib/cnb/api/public";

const ButtonPresentation: React.FC<{
  formal: ExtendedFormState<Button>;
}> = ({ formal }) => {
  const buttonType = formal.values.type;
  const [bgColor, iconColor] =
    buttonType === "SINGLE"
      ? ["actions[0].backgroundColor", "actions[0].iconColor"]
      : [
          "multiButtonOptions.iconBackgroundColor",
          "multiButtonOptions.iconColor",
        ];
  const hasError =
    (formal.errors as any)[bgColor] || (formal.errors as any)[iconColor];

  const showMulti = formal.values.type === "MULTI";

  const handleFlowerChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const cssClasses = event.target.checked ? "cnb-multi-flower" : "";
    formal.setValue("options.cssClasses", cssClasses);
  };

  const isFlower =
    showMulti && formal.values.options.cssClasses?.includes("cnb-multi-flower");
  return (
    <ExpansionPanel
      title="Presentation Settings"
      titleColor={hasError ? "error.main" : "text.primary"}
    >
      <FormGroup>
        {buttonType !== "MULTI" && (
          <>
            <ColorPicker
              label="Button color"
              {...formal.getTextFieldProps(bgColor)}
            />
            <ColorPicker
              label="Icon color"
              {...formal.getTextFieldProps(iconColor)}
            />
          </>
        )}
        {buttonType === "MULTI" && <MultiButtonPresentation formal={formal} />}
        <ButtonPositionField formal={formal} />
        {isFlower && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={isFlower}
                  onChange={handleFlowerChange}
                  color="primary"
                />
              }
              label="Flower"
            />
            <TextField
              label="Additional CSS Classes"
              type="text"
              {...formal.getTextFieldProps(`options.cssClasses`)}
            />
          </>
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export const ButtonPositionField: React.FC<{
  formal: ExtendedFormState<Button>;
}> = ({ formal }) => {
  const [label, placements] =
    formal.values.type === "FULL"
      ? ["Bar position", ButtonType.FULL_PLACEMENT_NAME]
      : ["Button position", ButtonType.PLACEMENT_NAME];
  return (
    <TextField
      label={label}
      select
      SelectProps={{ native: true }}
      {...formal.getTextFieldProps("options.placement")}
    >
      {Object.entries(placements).map(([place, name]) => (
        <option key={place} value={place}>
          {name}
        </option>
      ))}
    </TextField>
  );
};

export default ButtonPresentation;
