/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Validation } from './Validation';
import {
    ValidationFromJSON,
    ValidationFromJSONTyped,
    ValidationToJSON,
    ValidationToJSONTyped,
} from './Validation';

/**
 * Validation result for a specific entity
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
    /**
     * ID of the entity that was validated. Could be the ID of a Button/Action/Condition/Domain
     * @type {string}
     * @memberof ValidationResult
     */
    id?: string;
    /**
     * List of validation messages
     * @type {Array<Validation>}
     * @memberof ValidationResult
     */
    messages: Array<Validation>;
}

/**
 * Check if a given object implements the ValidationResult interface.
 */
export function instanceOfValidationResult(value: object): value is ValidationResult {
    if (!('messages' in value) || value['messages'] === undefined) return false;
    return true;
}

export function ValidationResultFromJSON(json: any): ValidationResult {
    return ValidationResultFromJSONTyped(json, false);
}

export function ValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'messages': ((json['messages'] as Array<any>).map(ValidationFromJSON)),
    };
}

  export function ValidationResultToJSON(json: any): ValidationResult {
      return ValidationResultToJSONTyped(json, false);
  }

  export function ValidationResultToJSONTyped(value?: ValidationResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'messages': ((value['messages'] as Array<any>).map(ValidationToJSON)),
    };
}

