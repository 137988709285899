import { ResponseContext } from "../lib/cnb/api/public";

/**
 * Custom error class for handling API server response errors.
 *
 * These are the errors coming from the API server that we want to handle
 */
class ApiResponseError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
    this.name = "ApiResponseError";
  }
}

const apiServerErrorHandlingMiddleware = {
  post: async (context: ResponseContext) => {
    if (context.response.status < 400) {
      return undefined;
    }
    const error = await context.response.json();
    if ("message" in error) {
      throw new ApiResponseError(context.response.status, error.message);
    }
    // This isn't a ResponseError or API error that we can cast,
    // so we don't know what to do with it.
    return undefined;
  },
};

export { apiServerErrorHandlingMiddleware };
