import { ActionType } from "../buttonModels";
import { Button, Action, ActionActionTypeEnum } from "../../lib/cnb/api/public";
import TextField from "@mui/material/TextField";
import React from "react";
import { ExtendedFormState } from "../../common";
import { Box } from "@mui/material";

type ActionValueProps = {
  actionType: ActionActionTypeEnum;
  formal: ExtendedFormState<Button | Action>;
  pathPrefix: string;
};

const ActionValue = ({ actionType, formal, pathPrefix }: ActionValueProps) => {
  // Check if we should have an empty (hidden) field
  if (ActionType.VALUE_HIDDEN[actionType]) {
    return (
      <Box sx={{ display: "none" }}>
        <TextField {...formal.getTextFieldProps(`${pathPrefix}actionValue`)} />
      </Box>
    );
  }
  return (
    <TextField
      label={ActionType.VALUE_LABEL[actionType]}
      type={ActionType.VALUE_INPUT_TYPE[actionType]}
      {...formal.getTextFieldProps(`${pathPrefix}actionValue`)}
      helperText={
        (formal.errors as any)[`${pathPrefix}actionValue`] ||
        (actionType === "WHATSAPP" &&
          "WhatsApp requires full international number notation") ||
        " "
      }
    />
  );
};

export default ActionValue;
