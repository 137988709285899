/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Button } from './Button';
import {
    ButtonFromJSON,
    ButtonFromJSONTyped,
    ButtonToJSON,
    ButtonToJSONTyped,
} from './Button';

/**
 * 
 * @export
 * @interface ImportButtonResponse
 */
export interface ImportButtonResponse {
    /**
     * 
     * @type {Array<Button>}
     * @memberof ImportButtonResponse
     */
    buttons: Array<Button>;
}

/**
 * Check if a given object implements the ImportButtonResponse interface.
 */
export function instanceOfImportButtonResponse(value: object): value is ImportButtonResponse {
    if (!('buttons' in value) || value['buttons'] === undefined) return false;
    return true;
}

export function ImportButtonResponseFromJSON(json: any): ImportButtonResponse {
    return ImportButtonResponseFromJSONTyped(json, false);
}

export function ImportButtonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportButtonResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'buttons': ((json['buttons'] as Array<any>).map(ButtonFromJSON)),
    };
}

  export function ImportButtonResponseToJSON(json: any): ImportButtonResponse {
      return ImportButtonResponseToJSONTyped(json, false);
  }

  export function ImportButtonResponseToJSONTyped(value?: ImportButtonResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'buttons': ((value['buttons'] as Array<any>).map(ButtonToJSON)),
    };
}

