import { FC, Fragment, useContext, useEffect } from "react";
import MuiButton from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import { ExtendedFormState, ExpansionPanel, ProFeature } from "../../common";
import { DomainContext } from "../../domain";
import { ButtonType, ConditionType } from "../buttonModels";
import { ConditionTypeRestrictions } from "../DomainRestrictions";
import { Button, Condition, Domain } from "../../lib/cnb/api/public";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles()((theme) => ({
  condition: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    padding: theme.spacing(1, 1, 0),
    "label + &": {
      marginTop: 8,
    },
    "&:last-child": {
      marginBottom: 8,
    },
    "& > button": {
      margin: theme.spacing(-1, -1, 0, 0),
    },
    "& > *:last-child": {
      flexBasis: "100%",
    },
  },
  addButton: {
    textTransform: "none",
    borderStyle: "dashed",
    color: theme.palette.action.disabled,
    transitionProperty: "background-color, box-shadow, border, color",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  proFeature: {
    marginTop: theme.spacing(1),
  },
}));

const Visibility: FC<{
  formal: ExtendedFormState<Button>;
}> = ({ formal }) => {
  const { classes } = useStyles();
  const { currentDomain } = useContext(DomainContext);
  useEffect(() => {
    if (formal.values.conditions.length === 0) {
      formal.change(
        "conditions",
        formal.values.conditions.concat(ConditionType.INITIAL),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formal.values.conditions]);

  const handleAdd = () => {
    formal.change(
      "conditions",
      formal.values.conditions.concat(ConditionType.INITIAL),
    );
  };
  const handleDelete = (index: number) => {
    const conditions = [...formal.values.conditions];
    conditions.splice(index, 1);
    formal.change("conditions", conditions);
  };

  return (
    <ExpansionPanel title="Visibility">
      <FormGroup>
        <TextField
          label="Show button on"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps("options.displayMode")}
        >
          {Object.entries(ButtonType.DISPLAY_MODE_NAME).map(([mode, name]) => (
            <option key={mode} value={mode}>
              {name}
            </option>
          ))}
        </TextField>
        {currentDomain?.type === "PRO" && (
          <TextField
            label="Appear after scroll"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">pixels</InputAdornment>
              ),
            }}
            {...formal.getTextFieldProps(`options.scroll.revealAtHeight`)}
            helperText="Button appears after above pixels are scrolled down"
          />
        )}
        <FormControl margin="normal">
          <FormLabel>Display rules</FormLabel>
          {formal.values.conditions.map((c, i) => (
            <Fragment key={i}>
              <div className={classes.condition}>
                <TextField
                  select
                  SelectProps={{
                    native: true,
                  }}
                  {...formal.getTextFieldProps(`conditions[${i}].filterType`)}
                >
                  {Object.entries(ConditionType.FILTER_NAME).map(
                    ([filter, name]) => (
                      <option key={filter} value={filter}>
                        {name}
                      </option>
                    ),
                  )}
                </TextField>
                <IconButton onClick={() => handleDelete(i)} size="large">
                  <CloseIcon />
                </IconButton>
                <TextField
                  select
                  SelectProps={{
                    native: true,
                  }}
                  {...formal.getTextFieldProps(`conditions[${i}].matchType`)}
                >
                  {Object.entries(ConditionType.MATCH_NAME)
                    .filter(([match]) =>
                      ConditionTypeRestrictions[
                        match as Condition["matchType"]
                      ].includes(currentDomain?.type as Domain["type"]),
                    )
                    .map(([match, name]) => (
                      <option key={match} value={match}>
                        {name}
                      </option>
                    ))}
                </TextField>
                <TextField
                  sx={{
                    display: c.matchType === "AGENTS_AVAILABLE" ? "none" : "",
                  }}
                  inputProps={
                    c.matchType === "COUNTRY_CODE"
                      ? {
                          maxlength: 2,
                        }
                      : {}
                  }
                  {...formal.getTextFieldProps(`conditions[${i}].matchValue`)}
                />
                {c.matchType === "COUNTRY_CODE" && (
                  <FormHelperText>
                    2 letter country code (e.g. <code>NL</code>) or 3 letter
                    country code (e.g. <code>DEU</code>). See{" "}
                    <a href={"https://www.iban.com/country-codes"}>
                      Country codes
                    </a>{" "}
                    as a reference.
                  </FormHelperText>
                )}
              </div>
              <Typography variant="overline" align="center">
                or
              </Typography>
            </Fragment>
          ))}
          <MuiButton
            variant="outlined"
            className={classes.addButton}
            onClick={handleAdd}
          >
            + add
          </MuiButton>
        </FormControl>
        {currentDomain?.type === "STARTER" && (
          <Box className={classes.proFeature}>
            <ProFeature
              featureName={"Chat, Country, parameter and RegEx rules are"}
              plural={true}
            />
          </Box>
        )}
        {currentDomain?.type === "FREE" && (
          <Box className={classes.proFeature}>
            <ProFeature
              featureName={"Chat and Country rules are"}
              plural={true}
            />
          </Box>
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default Visibility;
