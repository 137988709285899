import React from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import IconTextChooser from "./IconTextChooser";
import { ButtonType } from "../buttonModels";
import { ExpansionPanel, ExtendedFormState } from "../../common";
import ColorPicker from "./ColorPicker";
import { Button } from "../../lib/cnb/api/public";

const MultiButtonPresentation: React.FC<{
  formal: ExtendedFormState<Button>;
}> = ({ formal }) => {
  return (
    <>
      <ExpansionPanel title="Collapsed icon">
        <ColorPicker
          label="Button color"
          {...formal.getTextFieldProps(
            "multiButtonOptions.iconBackgroundColorOpen",
          )}
        />
        <ColorPicker
          label="Icon color"
          {...formal.getTextFieldProps("multiButtonOptions.iconColorOpen")}
        />

        <IconTextChooser
          iconTexts={ButtonType.MULTI_ICON_TEXT_OPEN}
          name={`multiButtonOptions.iconTextOpen`}
          type={`multiButtonOptions.iconTypeOpen`}
          formal={formal}
        />
        <TextField
          label="Custom image URL"
          type="url"
          {...formal.getTextFieldProps(
            `multiButtonOptions.iconBackgroundImageOpen`,
          )}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  formal.setValue(
                    `multiButtonOptions.iconBackgroundImageOpen`,
                    "",
                  )
                }
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
      </ExpansionPanel>
      <ExpansionPanel title="Expanded icon">
        <ColorPicker
          label="Button color"
          {...formal.getTextFieldProps(
            "multiButtonOptions.iconBackgroundColorClose",
          )}
        />
        <ColorPicker
          label="Icon color"
          {...formal.getTextFieldProps("multiButtonOptions.iconColorClose")}
        />
        <IconTextChooser
          iconTexts={ButtonType.MULTI_ICON_TEXT_CLOSE}
          name={`multiButtonOptions.iconTextClose`}
          type={`multiButtonOptions.iconTypeClose`}
          formal={formal}
        />
        <TextField
          label="Custom image URL"
          type="url"
          {...formal.getTextFieldProps(
            `multiButtonOptions.iconBackgroundImageClose`,
          )}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  formal.setValue(
                    `multiButtonOptions.iconBackgroundImageClose`,
                    "",
                  )
                }
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
      </ExpansionPanel>
      <ExpansionPanel title="Collapsed label">
        <TextField
          label="Label text"
          {...formal.getTextFieldProps(`multiButtonOptions.labelTextOpen`)}
        />
        <ColorPicker
          label="Background color"
          {...formal.getTextFieldProps(
            "multiButtonOptions.labelBackgroundColorOpen",
          )}
        />
        <ColorPicker
          label="Text color"
          {...formal.getTextFieldProps("multiButtonOptions.labelTextColorOpen")}
        />
      </ExpansionPanel>
      <ExpansionPanel title="Expanded label">
        <TextField
          label="Label text"
          {...formal.getTextFieldProps(`multiButtonOptions.labelTextClose`)}
        />
        <ColorPicker
          label="Background color"
          {...formal.getTextFieldProps(
            "multiButtonOptions.labelBackgroundColorClose",
          )}
        />
        <ColorPicker
          label="Text color"
          {...formal.getTextFieldProps(
            "multiButtonOptions.labelTextColorClose",
          )}
        />
      </ExpansionPanel>
    </>
  );
};

export default MultiButtonPresentation;
