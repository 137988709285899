/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApikeyUpdateRequest
 */
export interface ApikeyUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ApikeyUpdateRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the ApikeyUpdateRequest interface.
 */
export function instanceOfApikeyUpdateRequest(value: object): value is ApikeyUpdateRequest {
    return true;
}

export function ApikeyUpdateRequestFromJSON(json: any): ApikeyUpdateRequest {
    return ApikeyUpdateRequestFromJSONTyped(json, false);
}

export function ApikeyUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApikeyUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
    };
}

  export function ApikeyUpdateRequestToJSON(json: any): ApikeyUpdateRequest {
      return ApikeyUpdateRequestToJSONTyped(json, false);
  }

  export function ApikeyUpdateRequestToJSONTyped(value?: ApikeyUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
    };
}

