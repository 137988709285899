import Avatar from "@mui/material/Avatar";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import { makeStyles } from "tss-react/mui";
import { compile as pathCompile } from "path-to-regexp";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { routes, SpinnerContext } from "../../common";
import { DomainContext } from "../../domain";
import { ActionType, ButtonType } from "../buttonModels";
import { ButtonContext } from "../useButton";
import { ListItemButton } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { Button } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  avatarEnabled: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
  },
  avatarDisabled: {
    color: theme.palette.action.disabled,
    backgroundColor: "transparent",
  },
  textDisabled: {
    opacity: 0.6,
  },
}));

const getIconText = (button: Button) => {
  switch (button.type) {
    case "SINGLE":
      return (
        button.actions[0]?.iconText ||
        ActionType.ICON_TEXT[button.actions[0]?.actionType ?? "PHONE"][0]
      );
    case "MULTI":
      return (
        (button.multiButtonOptions && button.multiButtonOptions.iconTextOpen) ||
        ButtonType.MULTI_ICON_TEXT_OPEN[0].iconText
      );
    case "FULL":
      return ButtonType.FULL_ICON_TEXT;
    case "DOTS":
      return ButtonType.FULL_ICON_TEXT;
    default:
      throw new Error("unsupported button type");
  }
};

const ButtonList: React.FC = () => {
  const { classes, cx } = useStyles();
  const { buttons, activateButton } = React.useContext(ButtonContext);
  const { currentDomain } = React.useContext(DomainContext);
  const { withSpinner } = React.useContext(SpinnerContext);
  const editPath = pathCompile(routes.BUTTON_EDIT.path);
  const currentDomainButtons = Object.values(buttons || {}).filter(
    (button) => button.domain.id === currentDomain?.id,
  );
  const handleSetActive = (id: string) => (_: any, checked: boolean) => {
    withSpinner(activateButton(id, checked));
  };

  return (
    <List>
      {currentDomainButtons.map((button) => {
        const iconText = getIconText(button);
        const typeName = ButtonType.TYPE_NAME[button.type];
        return (
          <ListItem
            secondaryAction={
              <Switch
                edge="end"
                color="primary"
                onChange={handleSetActive(button.id)}
                checked={button.active}
              />
            }
            key={button.id}
            disablePadding
          >
            <ListItemButton
              component={RouterLink}
              to={editPath({ bid: button.id })}
            >
              <ListItemAvatar>
                <Avatar
                  className={cx({
                    [classes.avatarEnabled]: button.active,
                    [classes.avatarDisabled]: !button.active,
                  })}
                >
                  <Icon baseClassName="cnb-icons">{iconText}</Icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={button.name}
                secondary={typeName}
                className={cx({ [classes.textDisabled]: !button.active })}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ButtonList;
