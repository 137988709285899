import { WithId } from "../common";
import { actionApi, buttonApi, conditionApi } from "../cnb/apis";
import {
  Action,
  Button,
  type ButtonCreateRequest,
  ButtonUpdateRequest,
  Condition,
} from "../lib/cnb/api/public";

const api = {
  getButtons: async (): Promise<Button[]> => buttonApi.getAllFull(),

  createButton: async (button: ButtonCreateRequest): Promise<Button> =>
    buttonApi.createFull({ buttonCreateRequest: button }),

  updateButton: async (button: WithId<ButtonUpdateRequest>): Promise<Button> =>
    buttonApi.updateFull({ buttonId: button.id, buttonUpdateRequest: button }),

  deleteButton: async (id: Button["id"]) => buttonApi.delete({ buttonId: id }),

  createAction: async (action: Action): Promise<Action> =>
    actionApi.create({ actionCreateRequest: action }),

  updateAction: async (action: WithId<Action>): Promise<Action> =>
    actionApi.update({ actionId: action.id, actionUpdateRequest: action }),

  createCondition: async (condition: Condition): Promise<Condition> =>
    conditionApi.create({ conditionCreateRequest: condition }),

  updateCondition: async (condition: WithId<Condition>): Promise<Condition> =>
    conditionApi.update({
      conditionId: condition.id,
      conditionUpdateRequest: condition,
    }),
};

export default api;

const demoGetModels =
  <M>(name: string) =>
  (): Promise<M[]> => {
    const models = JSON.parse(localStorage.getItem(name) || "[]");
    return Promise.resolve(models);
  };

const demoUpdateModel =
  <M>(name: string, initial: M) =>
  (model: WithId<M>): Promise<M> => {
    const models: WithId<M>[] = JSON.parse(localStorage.getItem(name) || "[]");
    const modelIndex = model.id
      ? models.findIndex((m) => m.id === model.id)
      : models.length;
    models[modelIndex] = {
      ...initial,
      id: String(Date.now() + modelIndex),
      ...(model as M),
    };
    localStorage.setItem(name, JSON.stringify(models));
    return Promise.resolve(models[modelIndex]);
  };

const demoDeleteModel = (name: string) => (id: string) => {
  const models: WithId<unknown>[] = JSON.parse(
    localStorage.getItem(name) || "[]",
  );
  localStorage.setItem(name, JSON.stringify(models.filter((m) => m.id !== id)));
  return Promise.resolve({
    success: true,
    id: "0",
    object: {} as any,
  });
};

export const demoApi: typeof api = {
  getButtons: demoGetModels("buttons"),
  createButton: demoUpdateModel("buttons", {
    id: "test-id",
    active: true,
    name: "",
    type: "SINGLE",
    options: {
      placement: "BOTTOM_RIGHT",
    },
    actions: [],
    conditions: [],
  } as any),
  updateButton: demoUpdateModel("buttons", {} as any),
  deleteButton: demoDeleteModel("buttons"),

  createAction: demoUpdateModel("action", {
    actionType: "PHONE",
    actionValue: "",
    backgroundColor: "#009900",
    iconColor: "#FFFFFF",
    iconEnabled: null,
    labelText: "",
    schedule: {},
  } as any),
  updateAction: demoUpdateModel("action", {} as any),

  createCondition: demoUpdateModel("condition", {
    conditionType: "URL",
    matchType: "SIMPLE",
    matchValue: "",
  } as any),
  updateCondition: demoUpdateModel("condition", {} as any),
};
