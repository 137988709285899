import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import React from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { FormActions, timezonesGrouped, useExtendedForm } from "../common";
import { DomainType } from "./domainModels";
import { DomainContext } from "./useDomain";
import { Domain } from "../lib/cnb/api/public";

export const schema = yup
  .object<Domain>()
  .shape({
    name: yup
      .string()
      .matches(/^[^:/]*$/, "please only enter the domain name")
      .required("set the domain's name"),
    timezone: yup.string().required("set the domain's timezone"),
  })
  .defined();

const NewDomain: React.FC = () => {
  const history = useHistory();
  const { createDomain, setCurrentDomain } = React.useContext(DomainContext);
  const formal = useExtendedForm<Domain>({
    initialValues: {
      ...DomainType.INITIAL,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    } as Domain,
    schema,
    onSubmit: async (values) => {
      const newId = await createDomain(values);
      setCurrentDomain(newId);
      history.goBack();
    },
    onReset: () => history.goBack(),
  });

  return (
    <form {...formal.getFormProps()}>
      <FormGroup>
        <TextField
          label="Domain name"
          {...formal.getTextFieldProps("name")}
          helperText={
            formal.errors["name"] ||
            "Enter a domain without http:// or https://"
          }
        />
        <TextField
          label="Timezone"
          margin="normal"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps("timezone")}
          helperText={
            formal.errors["timezone"] ||
            "The primary timezone of your website's visitors"
          }
        >
          {Object.entries(timezonesGrouped).map(([continent, zones]) => (
            <optgroup key={continent} label={continent}>
              {zones.map((zone) => (
                <option key={zone.value} value={zone.value}>
                  {zone.text}
                </option>
              ))}
            </optgroup>
          ))}
        </TextField>
        <FormActions formal={formal} />
        {formal.submitState.state === "error" && (
          <FormHelperText error>
            {formal.submitState.message || "Could not create a new domain."}
          </FormHelperText>
        )}
      </FormGroup>
    </form>
  );
};

export default NewDomain;
