import { Domain, StripePlan } from "../lib/cnb/api/public";

export type DomainType = Domain & {
  properties?: Partial<
    Record<"scale" | "zindex" | "debug" | "allowMultipleButtons", string>
  >;
};

export const DomainType = {
  INITIAL: {
    name: "",
    type: "STARTER",
    trackGA: false,
    trackConversion: false,
    properties: {
      scale: "1.0",
      zindex: "2147483647",
      debug: "false",
      allowMultipleButtons: "true",
    },
  } as Partial<Domain>,

  DOMAIN_STATUS: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    SUSPENDED: "Suspended",
    TRIALING: "Trial",
  } as Record<Domain["status"], string>,
  TYPE_NAME: {
    FREE: "Free",
    PRO: "Pro",
    STARTER: "Starter",
  } as Record<Domain["type"], string>,
};

export const CurrencyType = {
  NUMBER_FORMAT: {
    EUR: new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }),
    USD: new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }),
  } as Record<StripePlan["currency"], Intl.NumberFormat>,
};

export const StripePlanType = {
  NICKNAME_PREFIX: "powered-by-",
};
