/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A Stripe Agency plan price.
 * @export
 * @interface StripeAgencyPlanPrice
 */
export interface StripeAgencyPlanPrice {
    /**
     * 
     * @type {number}
     * @memberof StripeAgencyPlanPrice
     */
    price: number;
    /**
     * A Stripe currency supported by CNB.
     * @type {string}
     * @memberof StripeAgencyPlanPrice
     */
    currency: StripeAgencyPlanPriceCurrencyEnum;
}


/**
 * @export
 */
export const StripeAgencyPlanPriceCurrencyEnum = {
    Eur: 'EUR',
    Usd: 'USD'
} as const;
export type StripeAgencyPlanPriceCurrencyEnum = typeof StripeAgencyPlanPriceCurrencyEnum[keyof typeof StripeAgencyPlanPriceCurrencyEnum];


/**
 * Check if a given object implements the StripeAgencyPlanPrice interface.
 */
export function instanceOfStripeAgencyPlanPrice(value: object): value is StripeAgencyPlanPrice {
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function StripeAgencyPlanPriceFromJSON(json: any): StripeAgencyPlanPrice {
    return StripeAgencyPlanPriceFromJSONTyped(json, false);
}

export function StripeAgencyPlanPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeAgencyPlanPrice {
    if (json == null) {
        return json;
    }
    return {
        
        'price': json['price'],
        'currency': json['currency'],
    };
}

  export function StripeAgencyPlanPriceToJSON(json: any): StripeAgencyPlanPrice {
      return StripeAgencyPlanPriceToJSONTyped(json, false);
  }

  export function StripeAgencyPlanPriceToJSONTyped(value?: StripeAgencyPlanPrice | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'price': value['price'],
        'currency': value['currency'],
    };
}

