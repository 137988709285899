import React from "react";
import TextField from "@mui/material/TextField";
import { ActionType } from "../../buttonModels";
import { ActionPropsProps } from "../ActionProps";

const MapSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  return (
    <TextField
      label="Map display"
      select
      SelectProps={{
        native: true,
      }}
      {...formal.getTextFieldProps(`${pathPrefix}properties.map-query-type`)}
    >
      {Object.entries(ActionType.MAP_QUERY_OPTIONS).map(([value, name]) => (
        <option key={value} value={value}>
          {name}
        </option>
      ))}
    </TextField>
  );
};

export default MapSettings;
