import {
  FormalWebTextFieldEvent,
  default as useFormal,
  FormalWebState,
} from "@kevinwolf/formal-web";
import cloneDeep from "lodash/cloneDeep";
import getIn from "lodash/get";
import setIn from "lodash/set";
import toPath from "lodash/toPath";
import React from "react";
import { Schema as YupSchema } from "yup";

export const useExtendedForm = <Schema>(props: {
  initialValues: Schema;
  schema: YupSchema<Schema>;
  onSubmit: (values: Schema) => void | Promise<unknown>;
  onReset?: () => void;
}) => {
  const [submitState, setSubmitState] = React.useState<
    { state: "initial" | "success" } | { state: "error"; message: string }
  >({ state: "initial" });

  const formal = useFormal(props.initialValues, {
    schema: props.schema,
    onSubmit: async (values) => {
      setSubmitState({ state: "initial" });
      try {
        await props.onSubmit(values);
        setSubmitState({ state: "success" });
      } catch (e: any) {
        setSubmitState({ state: "error", message: e.message });
      }
    },
  });

  const getGeneralProps = (path: string | keyof Schema) => {
    const [field, ...subPath] = toPath(path);
    let value;
    let onChange;
    const { disabled, ...props } = formal.getFieldProps(field as keyof Schema);
    if (!subPath.length) {
      value = props.value;
      onChange = props.onChange;
    } else {
      value = getIn(props.value, subPath);
      onChange = (e: FormalWebTextFieldEvent) =>
        props.onChange({
          target: {
            value: setIn(cloneDeep(props.value), subPath, e.target.value),
          },
        });
    }
    return {
      value,
      onChange,
      disabled,
    };
  };

  return {
    ...formal,

    submitState,

    getValue: <T>(path: string | keyof Schema): T =>
      getIn(formal.values, path) as T,

    setValue: (path: string | keyof Schema, value: any) => {
      const [field, ...subPath] = toPath(path);
      const formValue =
        typeof value === "object"
          ? { ...cloneDeep(getIn(formal.values, path)), ...value }
          : value;
      formal.change(
        field as keyof Schema,
        subPath.length
          ? setIn(
              cloneDeep(formal.values[field as keyof Schema]) as any,
              subPath,
              formValue,
            )
          : formValue,
      );
    },

    getTextFieldProps: (path: string | keyof Schema) => {
      const { value, onChange, disabled } = getGeneralProps(path);
      const error = formal.errors[path as keyof Schema];
      return {
        id: path,
        name: path,
        helperText: (error || " ") as string,
        error: !!error,
        disabled,
        value,
        onChange,
      };
    },

    getCheckboxProps: (
      path: string | keyof Schema,
      isString: boolean = false,
    ) => {
      const { value, onChange, disabled } = getGeneralProps(path);
      return {
        id: path,
        name: path,
        value: path,
        disabled,
        checked: isString ? value === "true" : Boolean(value),
        onChange: (_: any, ck: boolean) =>
          onChange({
            target: { value: isString ? ck.toString() : (ck as any) },
          }),
      };
    },

    getRadioGroupProps: (path: string | keyof Schema) => {
      const { value, onChange, disabled } = getGeneralProps(path);
      return {
        id: path,
        name: path,
        disabled,
        value,
        onChange,
      };
    },

    getSliderProps: (
      path: string | keyof Schema,
      isString: boolean = false,
    ) => {
      const { value, onChange, disabled } = getGeneralProps(path);
      return {
        id: path,
        name: path,
        disabled,
        value: Number(value),
        onChange: (_: any, nm: number | number[]) =>
          onChange({
            target: { value: isString ? nm.toString() : (nm as any) },
          }),
      };
    },

    getResetButtonProps: () => ({
      ...formal.getResetButtonProps(),
      onClick: () => {
        formal.reset();
        props.onReset?.();
      },
      disabled: formal.isValidating || formal.isSubmitting,
    }),
  };
};

export type ExtendedFormState<Schema> = FormalWebState<Schema> &
  Omit<ReturnType<typeof useExtendedForm>, keyof FormalWebState<Schema>>;
