/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CnbPromotionCodeRestrictions } from './CnbPromotionCodeRestrictions';
import {
    CnbPromotionCodeRestrictionsFromJSON,
    CnbPromotionCodeRestrictionsFromJSONTyped,
    CnbPromotionCodeRestrictionsToJSON,
    CnbPromotionCodeRestrictionsToJSONTyped,
} from './CnbPromotionCodeRestrictions';

/**
 * 
 * @export
 * @interface PromotionCode
 */
export interface PromotionCode {
    /**
     * 
     * @type {string}
     * @memberof PromotionCode
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionCode
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionCode
     */
    amountOff?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionCode
     */
    percentOff?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionCode
     */
    redeemBy?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionCode
     */
    duration: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionCode
     */
    durationInMonths?: number;
    /**
     * 
     * @type {CnbPromotionCodeRestrictions}
     * @memberof PromotionCode
     */
    restrictions: CnbPromotionCodeRestrictions;
}

/**
 * Check if a given object implements the PromotionCode interface.
 */
export function instanceOfPromotionCode(value: object): value is PromotionCode {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('restrictions' in value) || value['restrictions'] === undefined) return false;
    return true;
}

export function PromotionCodeFromJSON(json: any): PromotionCode {
    return PromotionCodeFromJSONTyped(json, false);
}

export function PromotionCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotionCode {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'amountOff': json['amountOff'] == null ? undefined : json['amountOff'],
        'percentOff': json['percentOff'] == null ? undefined : json['percentOff'],
        'redeemBy': json['redeemBy'] == null ? undefined : json['redeemBy'],
        'duration': json['duration'],
        'durationInMonths': json['durationInMonths'] == null ? undefined : json['durationInMonths'],
        'restrictions': CnbPromotionCodeRestrictionsFromJSON(json['restrictions']),
    };
}

  export function PromotionCodeToJSON(json: any): PromotionCode {
      return PromotionCodeToJSONTyped(json, false);
  }

  export function PromotionCodeToJSONTyped(value?: PromotionCode | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': value['code'],
        'name': value['name'],
        'amountOff': value['amountOff'],
        'percentOff': value['percentOff'],
        'redeemBy': value['redeemBy'],
        'duration': value['duration'],
        'durationInMonths': value['durationInMonths'],
        'restrictions': CnbPromotionCodeRestrictionsToJSON(value['restrictions']),
    };
}

