/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CnbPromotionCodeRestrictions
 */
export interface CnbPromotionCodeRestrictions {
    /**
     * 
     * @type {boolean}
     * @memberof CnbPromotionCodeRestrictions
     */
    firstTimeTransaction?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CnbPromotionCodeRestrictions
     */
    minimumAmount?: number;
}

/**
 * Check if a given object implements the CnbPromotionCodeRestrictions interface.
 */
export function instanceOfCnbPromotionCodeRestrictions(value: object): value is CnbPromotionCodeRestrictions {
    return true;
}

export function CnbPromotionCodeRestrictionsFromJSON(json: any): CnbPromotionCodeRestrictions {
    return CnbPromotionCodeRestrictionsFromJSONTyped(json, false);
}

export function CnbPromotionCodeRestrictionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CnbPromotionCodeRestrictions {
    if (json == null) {
        return json;
    }
    return {
        
        'firstTimeTransaction': json['firstTimeTransaction'] == null ? undefined : json['firstTimeTransaction'],
        'minimumAmount': json['minimumAmount'] == null ? undefined : json['minimumAmount'],
    };
}

  export function CnbPromotionCodeRestrictionsToJSON(json: any): CnbPromotionCodeRestrictions {
      return CnbPromotionCodeRestrictionsToJSONTyped(json, false);
  }

  export function CnbPromotionCodeRestrictionsToJSONTyped(value?: CnbPromotionCodeRestrictions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'firstTimeTransaction': value['firstTimeTransaction'],
        'minimumAmount': value['minimumAmount'],
    };
}

