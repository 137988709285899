import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";
import React from "react";

const SHOW_AT_LEAST = 800;

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
}));

const useSpinner = () => {
  const [visible, setVisible] = React.useState(false);

  const withSpinner = function <R>(value: Promise<R>) {
    setVisible(true);
    return Promise.all([
      value,
      new Promise((resolve) => setTimeout(resolve, SHOW_AT_LEAST)),
    ])
      .then(([v]) => v)
      .finally(() => setVisible(false));
  };

  return React.useMemo(
    () => ({
      visible,
      showSpinner: setVisible,
      withSpinner,
    }),
    [visible],
  );
};

export const SpinnerContext = React.createContext<
  Pick<ReturnType<typeof useSpinner>, "showSpinner" | "withSpinner">
>(null as any);

/**
 * The <code>SpinnerWrapper</code> grays out over the entire viewport as soon
 * <code>withSpinner</code> is invoked.
 *
 * It will remain in a "loading" state until the <code>Promise</code> provided
 * to <code>withSpinner</code> has resolved.
 */
const SpinnerWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { classes } = useStyles();
  const spinnerContextValue = useSpinner();

  return (
    <SpinnerContext.Provider value={spinnerContextValue}>
      {children}
      <Backdrop className={classes.root} open={spinnerContextValue.visible}>
        <CircularProgress />
      </Backdrop>
    </SpinnerContext.Provider>
  );
};

export default SpinnerWrapper;
