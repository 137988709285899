import Drawer from "@mui/material/Drawer";
import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    zIndex: 0,
    width: 400,
    padding: theme.spacing(3, 2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const Page: React.FC<{
  anchor?: "left" | "right";
  className?: string;
  children: React.ReactNode;
}> = ({ anchor, className, children }) => {
  const { classes, cx } = useStyles();

  return (
    <Drawer
      variant="permanent"
      anchor={anchor}
      classes={{
        paper: cx(classes.drawerPaper, className),
      }}
    >
      {children}
    </Drawer>
  );
};

export default Page;
