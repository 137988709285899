/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutSessionCreateRequest
 */
export interface CheckoutSessionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionCreateRequest
     */
    plan: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionCreateRequest
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionCreateRequest
     */
    callbackUri: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionCreateRequest
     */
    uiMode?: CheckoutSessionCreateRequestUiModeEnum;
}


/**
 * @export
 */
export const CheckoutSessionCreateRequestUiModeEnum = {
    Embedded: 'EMBEDDED',
    Hosted: 'HOSTED'
} as const;
export type CheckoutSessionCreateRequestUiModeEnum = typeof CheckoutSessionCreateRequestUiModeEnum[keyof typeof CheckoutSessionCreateRequestUiModeEnum];


/**
 * Check if a given object implements the CheckoutSessionCreateRequest interface.
 */
export function instanceOfCheckoutSessionCreateRequest(value: object): value is CheckoutSessionCreateRequest {
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('callbackUri' in value) || value['callbackUri'] === undefined) return false;
    return true;
}

export function CheckoutSessionCreateRequestFromJSON(json: any): CheckoutSessionCreateRequest {
    return CheckoutSessionCreateRequestFromJSONTyped(json, false);
}

export function CheckoutSessionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutSessionCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'plan': json['plan'],
        'domain': json['domain'],
        'callbackUri': json['callbackUri'],
        'uiMode': json['uiMode'] == null ? undefined : json['uiMode'],
    };
}

  export function CheckoutSessionCreateRequestToJSON(json: any): CheckoutSessionCreateRequest {
      return CheckoutSessionCreateRequestToJSONTyped(json, false);
  }

  export function CheckoutSessionCreateRequestToJSONTyped(value?: CheckoutSessionCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'plan': value['plan'],
        'domain': value['domain'],
        'callbackUri': value['callbackUri'],
        'uiMode': value['uiMode'],
    };
}

