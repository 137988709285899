import { DndContext, DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { SortableContext, useSortable, arrayMove } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FormalWebState } from "@kevinwolf/formal-web";
import MuiButton from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { compile as pathCompile } from "path-to-regexp";
import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { ProFeature, routes } from "../../common";
import { ActionType } from "../buttonModels";
import { Box, ListItemButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Action, Button } from "../../lib/cnb/api/public";

const getActionId = (a: Action) => a.id + a.actionType + a.actionValue;

const useStyles = makeStyles()((theme) => ({
  proFeature: {
    marginTop: theme.spacing(2),
  },
}));

const ActionList: React.FC<{
  formal: FormalWebState<Button>;
  disallowAddAction: boolean;
}> = ({ formal, disallowAddAction }) => {
  const { bid = "-new" } = useParams<{ bid: string }>();
  const newPath = pathCompile(routes.ACTION_NEW.path);
  const editPath = pathCompile(routes.ACTION_EDIT.path);

  const { classes } = useStyles();
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id.toString());
  };
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveId(null);
    if (!over || active.id === over.id) return;

    const actions = formal.values.actions;
    const oldIndex = actions.findIndex((a) => getActionId(a) === active.id);
    const newIndex = actions.findIndex((a) => getActionId(a) === over.id);
    formal.change("actions", arrayMove(actions, oldIndex, newIndex));
  };

  return (
    <FormGroup>
      <FormLabel>Action list</FormLabel>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext items={formal.values.actions.map(getActionId)}>
          <List>
            {formal.values.actions.map((action, index) => (
              <ActionListItem
                key={getActionId(action)}
                action={action}
                editPath={editPath({ bid, aid: index })}
                isSorting={getActionId(action) === activeId}
              />
            ))}
          </List>
        </SortableContext>
      </DndContext>
      <MuiButton
        variant="outlined"
        fullWidth
        size="large"
        disabled={disallowAddAction}
        component={RouterLink}
        to={newPath({ bid })}
      >
        <AddIcon />
        Add Action
      </MuiButton>
      {disallowAddAction && (
        <Box className={classes.proFeature}>
          <ProFeature featureName={"Multiple Actions for a Buttonbar are"} />
        </Box>
      )}
    </FormGroup>
  );
};

const ActionListItem: React.FC<{
  action: Action;
  editPath: string;
  isSorting: boolean;
}> = ({ action, editPath, isSorting }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: getActionId(action) });
  return (
    <ListItem
      secondaryAction={
        <IconButton disableRipple {...listeners}>
          <DragIndicatorIcon />
        </IconButton>
      }
      ref={setNodeRef}
      disablePadding
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      {...attributes}
    >
      <ListItemButton component={isSorting ? "div" : RouterLink} to={editPath}>
        <ListItemIcon>
          <Icon
            baseClassName="cnb-icons"
            color={!action.iconEnabled ? "disabled" : "primary"}
          >
            {action.iconText || ActionType.ICON_TEXT[action.actionType][0]}
          </Icon>
        </ListItemIcon>
        <ListItemText primary={action.labelText} />
      </ListItemButton>
    </ListItem>
  );
};

export default ActionList;
