import React from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import { FormField, ExpansionPanel, SpinnerContext } from "../common";
import { DomainList } from "../domain/components";
import { ApiKeys, PasswordChange } from "./components";
import { AuthContext } from "./useAuth";
import StorageSection from "./components/StorageSection";

const useStyles = makeStyles()((theme) => ({
  logout: {
    width: "max-content",
    alignSelf: "flex-end",
  },
  historyLink: {
    textAlign: "left",
  },
  domainList: {
    marginTop: theme.spacing(1),
  },
  breakAll: {
    wordBreak: "break-all",
  },
}));

const AccountSettings: React.FC = () => {
  const { classes } = useStyles();
  const { currentUser, openPaymentHistory, logout, updateUser } =
    React.useContext(AuthContext);
  const { withSpinner } = React.useContext(SpinnerContext);

  const isAccountPro = currentUser.roles?.some((role) =>
    role.startsWith("ROLE_PRO"),
  );

  const handlePaymentHistory = async () => {
    try {
      await openPaymentHistory();
    } catch {
      window.alert("Can't connect to Stripe for payment history 😞");
    }
  };
  const handleMarketingChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    await withSpinner(
      updateUser({ marketingData: { emailOptIn: event.target.checked } }),
    );
  };

  return (
    <FormGroup>
      <Button
        variant="contained"
        color="primary"
        className={classes.logout}
        onClick={logout}
      >
        Logout
      </Button>
      <FormField label="Account owner">
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.breakAll}
        >
          {currentUser.email}
        </Typography>
      </FormField>
      <FormField label="Account type">
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.breakAll}
        >
          {isAccountPro ? "Pro" : "Regular"}
        </Typography>
        <Link href="https://nowbuttons.com/pricing/" target="_blank">
          Learn more about account types
        </Link>
      </FormField>
      <ExpansionPanel title="Domains">
        <DomainList className={classes.domainList} />
      </ExpansionPanel>
      <ExpansionPanel title="Api keys">
        <ApiKeys />
      </ExpansionPanel>
      <ExpansionPanel title="Change your password">
        <PasswordChange />
      </ExpansionPanel>
      <FormControlLabel
        control={
          <Switch
            checked={currentUser.marketingData?.emailOptIn || false}
            onChange={handleMarketingChange}
            color="primary"
          />
        }
        label="Receive marketing communication"
      />
      <Box className={classes.breakAll}>
        <FormField label="Account ID" staticValue={currentUser.id} />
      </Box>
      <StorageSection />
      <FormField label="Billing">
        <Link
          component="button"
          variant="body1"
          className={classes.historyLink}
          onClick={handlePaymentHistory}
        >
          See payment history and invoices
        </Link>
      </FormField>
    </FormGroup>
  );
};

export default AccountSettings;
