import React from "react";
import { useHistory } from "react-router-dom";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import * as yup from "yup";
import {
  timezonesGrouped,
  FormActions,
  FormField,
  routes,
  RouteDrawer,
  useExtendedForm,
  CodeSnippet,
  ProFeature,
} from "../common";
import { AuthContext } from "../auth";
import { PaymentNote } from "./components";
import { DomainType } from "./domainModels";
import { DomainContext } from "./useDomain";
import SubscriptionPlan from "./SubscriptionPlan";
import OutstandingPaymentNote from "./components/OutstandingPaymentNote";
import RenewalSettings from "./components/RenewalSettings";
import { Domain } from "../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  outstandingPaymentNotice: {
    marginBottom: 6,
  },
  sliderField: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginRight: 6,
    "& > *:first-of-type": {
      flexGrow: 1,
    },
  },
  instructionsTitle: {
    marginTop: theme.spacing(1),
  },
  breakAll: {
    wordBreak: "break-all",
  },
}));

export const schema = yup
  .object<Domain>()
  .shape({
    timezone: yup.string().required("set the domain's timezone"),
  })
  .defined();

/**
 * Get the name (Starter, Free, or Pro (yearly / monthly)
 *
 * @param domain Domain
 */
const getPlanName = (domain: Domain) => {
  const interval =
    domain.type === "PRO" && domain.interval ? " " + domain.interval : "";
  return DomainType.TYPE_NAME[domain.type] + interval;
};

const DomainSettingsForm: React.FC<{ currentDomain: Domain }> = ({
  currentDomain,
}) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { currentUser } = React.useContext(AuthContext);
  const { updateDomain, getClientUrl } = React.useContext(DomainContext);
  const formal = useExtendedForm<Domain>({
    initialValues: {
      ...currentDomain,
      properties: currentDomain.properties ?? DomainType.INITIAL.properties,
    },
    schema,
    onSubmit: async (values) => {
      await updateDomain(values);
      history.goBack();
    },
    onReset: () => history.goBack(),
  });

  const zToScale = (z: any) => Math.ceil(Math.log10(Number(z)));
  const scaleToZ = (s: any) =>
    Math.round(2147483647 / Math.pow(10, 10 - Number(s))).toString();

  const planName = getPlanName(currentDomain);
  const userSnippet = `<script async src="${getClientUrl(
    currentUser.id,
  )}"></script>`;
  const domainSnippet = `<script async src="${getClientUrl(
    currentDomain.id,
  )}"></script>`;

  return (
    <>
      <form {...formal.getFormProps()}>
        <FormGroup>
          <PaymentNote />
          <Box className={classes.outstandingPaymentNotice}>
            <OutstandingPaymentNote snackbar={false} />
          </Box>
          <Box className={classes.breakAll}>
            <FormField label="Domain ID" staticValue={currentDomain.id} />
          </Box>
          <FormField label="Active domain" staticValue={currentDomain.name} />
          {planName && (
            <FormField label="Plan">
              <Typography variant="body1" color="textSecondary">
                {planName}
              </Typography>
            </FormField>
          )}
          <RenewalSettings currentDomain={currentDomain} formal={formal} />
          <Divider />
          <TextField
            label="Timezone"
            margin="normal"
            select
            SelectProps={{
              native: true,
            }}
            {...formal.getTextFieldProps("timezone")}
          >
            {Object.entries(timezonesGrouped).map(([continent, zones]) => (
              <optgroup key={continent} label={continent}>
                {zones.map((zone) => (
                  <option key={zone.value} value={zone.value}>
                    {zone.text}
                  </option>
                ))}
              </optgroup>
            ))}
          </TextField>
          <FormField>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formal.getCheckboxProps("trackGA")}
                />
              }
              label="Click tracking in Google Analytics"
            />
          </FormField>
          <FormField>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formal.getCheckboxProps("trackConversion")}
                />
              }
              label="Google Ads conversion tracking"
            />
          </FormField>
          <FormField>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formal.getCheckboxProps(
                    "properties.allowMultipleButtons",
                    true,
                  )}
                  disabled={currentDomain?.type === "STARTER"}
                />
              }
              label="Allow multiple buttons per page"
            />
          </FormField>
          {currentDomain?.type === "STARTER" && (
            <ProFeature featureName={"Multiple buttons per page are"} />
          )}
          <FormField className={classes.sliderField}>
            <Typography gutterBottom>Button scale</Typography>
            <Typography gutterBottom>
              {Math.round(Number(formal.getValue("properties.scale")) * 100)}%
            </Typography>
            <Slider
              marks
              min={0.7}
              max={1.3}
              step={0.1}
              {...formal.getSliderProps("properties.scale", true)}
            />
          </FormField>
          <FormField className={classes.sliderField}>
            <Typography gutterBottom>Z-index (order)</Typography>
            <Typography gutterBottom>
              {Number(formal.getValue("properties.zindex"))}
            </Typography>
            <Slider
              marks
              min={1}
              max={10}
              disabled={formal.getFieldProps("properties").disabled}
              value={zToScale(formal.getValue("properties.zindex"))}
              onChange={(_: any, nm: number | number[]) =>
                formal.setValue("properties.zindex", scaleToZ(nm))
              }
            />
          </FormField>
          <FormField>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formal.getCheckboxProps("properties.debug", true)}
                />
              }
              label="Debug mode"
            />
          </FormField>
          <FormActions formal={formal} />
          <Divider />
          <Typography
            variant="subtitle1"
            paragraph
            className={classes.instructionsTitle}
          >
            Installation instructions:
          </Typography>
          <Typography variant="body2" paragraph>
            Place the following code inside the &lt;body&gt; tag on your
            website.
          </Typography>
          <Typography variant="subtitle2">For current domain only:</Typography>
          <CodeSnippet>{domainSnippet}</CodeSnippet>
          <Typography variant="subtitle2" paragraph>
            Full account (all domains):
          </Typography>
          <Typography variant="body2">
            The script below can be used for multiple setups
          </Typography>
          <CodeSnippet>{userSnippet}</CodeSnippet>
          <Link href="https://nowbuttons.com/support/web-app/implementation/installing-on-single-website/">
            Learn more
          </Link>
        </FormGroup>
      </form>
      <RouteDrawer {...routes.DOMAIN_PLAN} drawerProps={{ anchor: "right" }}>
        <SubscriptionPlan />
      </RouteDrawer>
    </>
  );
};

const DomainSettings: React.FC = () => {
  const { currentDomain } = React.useContext(DomainContext);
  return currentDomain ? (
    <DomainSettingsForm key={currentDomain.id} currentDomain={currentDomain} />
  ) : null;
};

export default DomainSettings;
