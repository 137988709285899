import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ActionType } from "../../buttonModels";
import { ExpansionPanel, FormField, ProFeature } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { DomainContext } from "../../../domain";

const WhatsappSettings: React.FC<ActionPropsProps> = ({
  formal,
  pathPrefix,
}) => {
  const isWhatsAppDialog =
    formal.getValue(`${pathPrefix}properties.whatsapp-dialog-type`) ===
    ActionType.WHATSAPP_DIALOG_TRUTHY;

  const { currentDomain } = React.useContext(DomainContext);

  return (
    <ExpansionPanel title={"WhatsApp Settings"}>
      <FormGroup>
        <FormField>
          {currentDomain?.type === "STARTER" ? (
            <ProFeature featureName={"The WhatsApp chat window is"} />
          ) : (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formal.getCheckboxProps(
                    `${pathPrefix}properties.whatsapp-dialog-type`,
                  )}
                  checked={isWhatsAppDialog}
                  onChange={(_: any, ck: boolean) =>
                    formal.setValue(
                      `${pathPrefix}properties.whatsapp-dialog-type`,
                      ck ? ActionType.WHATSAPP_DIALOG_TRUTHY : undefined,
                    )
                  }
                />
              }
              label="WhatsApp chat window"
            />
          )}
        </FormField>
        {isWhatsAppDialog ? (
          <>
            <TextField
              label="Window title"
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.whatsapp-title`,
              )}
            />
            <TextField
              label="Welcome message"
              multiline
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.whatsapp-welcomeMessage`,
              )}
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formal.getCheckboxProps(
                    `${pathPrefix}properties.show-notification-count`,
                  )}
                />
              }
              label="Show red notification dot"
            />
            <TextField
              label="Placeholder message"
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.whatsapp-placeholderMessage`,
              )}
            />
          </>
        ) : (
          <TextField
            label="Default message"
            {...formal.getTextFieldProps(`${pathPrefix}properties.message`)}
            helperText="This helps visitors to formulate their questions to you"
          />
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default WhatsappSettings;
