import React from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import { ExtendedFormState } from "../../common";
import { ActionIconTypeSelection, ActionType } from "../buttonModels";
import { DomainContext } from "../../domain";
import { ActionTypeRestrictions } from "../DomainRestrictions";
import IconTextChooser from "./IconTextChooser";
import IframeSettings from "./actions/IframeSettings";
import TallySettings from "./actions/TallySettings";
import IntercomSettings from "./actions/IntercomSettings";
import LinkSettings from "./actions/LinkSettings";
import MapSettings from "./actions/MapSettings";
import EmailSettings from "./actions/EmailSettings";
import SmsSettings from "./actions/SmsSettings";
import WhatsappSettings from "./actions/WhatsappSettings";
import FacebookSettings from "./actions/FacebookSettings";
import SkypeSettings from "./actions/SkypeSettings";
import LineSettings from "./actions/LineSettings";
import ViberSettings from "./actions/ViberSettings";
import WechatSettings from "./actions/WechatSettings";
import ZaloSettings from "./actions/ZaloSettings";
import ChatSettings from "./actions/ChatSettings";
import { Action, Button, Domain } from "../../lib/cnb/api/public";
import ActionValue from "./ActionValue";
import { useAuth } from "../../auth";

export interface ActionPropsProps {
  formal: ExtendedFormState<Button | Action>;
  buttonType?: Button["type"];
  pathPrefix?: string;
}

const iconTextToTypeSelection = (
  iconTexts: string[],
  iconType: Action["iconType"] = "FONT",
): ActionIconTypeSelection => {
  return iconTexts.map((iconText) => {
    return { iconText: iconText, iconType: iconType };
  });
};

const ActionProps: React.FC<ActionPropsProps> = ({
  formal,
  buttonType = undefined,
  pathPrefix = "",
}) => {
  const { currentDomain } = React.useContext(DomainContext);

  const { currentUser } = useAuth();

  const actionType = formal.getValue<Action["actionType"]>(
    `${pathPrefix}actionType`,
  );

  const hasIconBackgroundImage =
    formal.getValue<string>(`${pathPrefix}iconBackgroundImage`)?.length > 0;

  const isDomainPro = currentDomain?.type === "PRO";

  /**
   * On any change of the ActionType, refresh the available icons.
   */
  React.useEffect(() => {
    const iconText = formal.getValue<string>(`${pathPrefix}iconText`);
    const includes = ActionType.ICON_TEXT[actionType].includes(iconText);
    if (!includes) {
      formal.setValue(
        `${pathPrefix}iconText`,
        ActionType.ICON_TEXT[actionType][0],
      );
    }
  }, [actionType, formal, pathPrefix]);

  /**
   * Some types (mostly CHAT) do not have an actionValue.
   * So in that case, we set it to /something/.
   */
  React.useEffect(() => {
    const shouldBeHidden = !!ActionType.VALUE_HIDDEN[actionType];
    if (shouldBeHidden && !formal.getValue(`${pathPrefix}actionValue`)) {
      formal.setValue(`${pathPrefix}actionValue`, "--no value--");
    }
    if (
      !shouldBeHidden &&
      formal.getValue(`${pathPrefix}actionValue`) === "--no value--"
    ) {
      formal.setValue(`${pathPrefix}actionValue`, "");
    }
  }, [actionType, formal, pathPrefix]);
  /**
   * If any value exists in the "hasIconBackgroundImage" field, that
   * means that this is a "CUSTOM" type.
   *
   * Otherwise, we simply default to "FONT" (for the icons)
   */
  React.useEffect(() => {
    formal.setValue(
      `${pathPrefix}iconType`,
      (hasIconBackgroundImage ? "CUSTOM" : "FONT") as Action["iconType"],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasIconBackgroundImage]);

  /**
   * Returns true if the actionType is a PRO-only feature and the current domain is NOT a PRO domain.
   */
  const isActionDisabled = (type: Action["actionType"]) =>
    !ActionTypeRestrictions[type].includes(
      currentDomain?.type as Domain["type"],
    );

  // Filter out CHAT if the user does not have the ROLE_CHAT_USER
  const actionTypes = Object.entries(ActionType.TYPE_NAME).filter(
    ([type]) =>
      !(type === "CHAT" && !currentUser?.roles.includes("ROLE_CHAT_USER")),
  );

  return (
    <>
      <TextField
        label="Action"
        select
        SelectProps={{
          native: true,
        }}
        {...formal.getTextFieldProps(`${pathPrefix}actionType`)}
      >
        {actionTypes.map(([type, name]) => (
          <option
            key={type}
            value={type}
            disabled={isActionDisabled(type as Action["actionType"])}
          >
            {name} {isActionDisabled(type as Action["actionType"]) && " (PRO)"}
          </option>
        ))}
      </TextField>
      {!hasIconBackgroundImage && (
        <IconTextChooser
          iconTexts={iconTextToTypeSelection(ActionType.ICON_TEXT[actionType])}
          name={`${pathPrefix}iconText`}
          type={`${pathPrefix}iconType`}
          formal={formal}
        />
      )}
      {isDomainPro && (
        <TextField
          label="Custom image URL"
          type="url"
          {...formal.getTextFieldProps(`${pathPrefix}iconBackgroundImage`)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  formal.setValue(`${pathPrefix}iconBackgroundImage`, "")
                }
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
      )}
      <TextField
        label={pathPrefix ? "Label (optional)" : "Label"}
        sx={buttonType === "DOTS" ? { display: "none" } : undefined}
        {...formal.getTextFieldProps(`${pathPrefix}labelText`)}
      />
      <ActionValue
        actionType={actionType}
        formal={formal}
        pathPrefix={pathPrefix}
      />
      {actionType === "CHAT" && (
        <>
          <IframeSettings
            formal={formal}
            pathPrefix={pathPrefix}
            includeWidthAndHeight={false}
          />
          <ChatSettings formal={formal} pathPrefix={pathPrefix} />
        </>
      )}

      {actionType === "EMAIL" && (
        <EmailSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "FACEBOOK" && (
        <FacebookSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "IFRAME" && (
        <IframeSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "INTERCOM" && (
        <IntercomSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "LINE" && (
        <LineSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "LINK" && (
        <LinkSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "MAP" && (
        <MapSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "SKYPE" && (
        <SkypeSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "SMS" && (
        <SmsSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "TALLY" && (
        <>
          <IframeSettings formal={formal} pathPrefix={pathPrefix} />
          <TallySettings formal={formal} pathPrefix={pathPrefix} />
        </>
      )}
      {actionType === "VIBER" && (
        <ViberSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "WECHAT" && (
        <WechatSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "WHATSAPP" && (
        <WhatsappSettings formal={formal} pathPrefix={pathPrefix} />
      )}
      {actionType === "ZALO" && (
        <ZaloSettings formal={formal} pathPrefix={pathPrefix} />
      )}
    </>
  );
};

export default ActionProps;
