import {
  Action,
  ActionIconTypeEnum,
  Button,
  Condition,
} from "../lib/cnb/api/public";

export type ActionIconTypeSelection = {
  iconText: string;
  iconType: ActionIconTypeEnum;
}[];

export const ButtonType = {
  INITIAL: {
    active: true,
    name: "",
    type: "SINGLE",
    options: {
      animation: "NONE",
      displayMode: "ALWAYS",
      placement: "BOTTOM_RIGHT",
      scroll: {
        revealAtHeight: 0,
        hideAtHeight: 0,
        neverHide: false,
      },
      cssClasses: "",
    },
    multiButtonOptions: {
      iconColor: "#FFFFFF",
      iconBackgroundColor: "#009900",
      iconTextOpen: "more_vert",
      iconTypeOpen: "FONT",
      iconTextClose: "close",
      iconTypeClose: "FONT",
    },
    actions: [],
    conditions: [],
  } as Partial<Button>,

  TYPE_NAME: {
    SINGLE: "Single button",
    MULTI: "Multibutton",
    FULL: "Buttonbar",
    DOTS: "Dots",
  } as Record<Button["type"], string>,

  ANIMATION_NAME: {
    NONE: "None",
    TADA: "Tada",
    SHAKE: "Shake",
    SONAR_LIGHT: "Sonar light (for dark backgrounds)",
    SONAR_DARK: "Sonar dark (for light backgrounds)",
  } as Record<NonNullable<Button["options"]["animation"]>, string>,

  DISPLAY_MODE_NAME: {
    MOBILE_ONLY: "Mobile only",
    DESKTOP_ONLY: "Desktop only",
    ALWAYS: "Mobile and desktop",
  } as Record<NonNullable<Button["options"]["displayMode"]>, string>,

  PLACEMENT_NAME: {
    BOTTOM_LEFT: "Bottom left",
    BOTTOM_CENTER: "Bottom center",
    BOTTOM_RIGHT: "Bottom right",
    MIDDLE_LEFT: "Middle left",
    MIDDLE_RIGHT: "Middle right",
    TOP_LEFT: "Top left",
    TOP_CENTER: "Top center",
    TOP_RIGHT: "Top right",
  } as Record<Button["options"]["placement"], string>,

  FULL_PLACEMENT_NAME: {
    BOTTOM_CENTER: "Bottom of screen",
    TOP_CENTER: "Top of screen",
    MIDDLE_LEFT: "Left",
    MIDDLE_RIGHT: "Right",
  } as Record<Button["options"]["placement"], string>,

  MULTI_ICON_TEXT_OPEN: [
    { iconText: "more_vert", iconType: "FONT" },
    { iconText: "menu", iconType: "FONT" },
    { iconText: "support", iconType: "FONT" },
    { iconText: "communicate", iconType: "FONT" },
    { iconText: "more_info", iconType: "FONT" },
    { iconText: "conversation", iconType: "FONT" },
    { iconText: "call3", iconType: "FONT" },
    { iconText: "whatsapp", iconType: "FONT" },
    { iconText: "email", iconType: "FONT" },
    { iconText: "call", iconType: "FONT" },
    { iconText: "directions3", iconType: "FONT" },
  ] as ActionIconTypeSelection,

  MULTI_ICON_TEXT_CLOSE: [
    { iconText: "close", iconType: "FONT" },
    { iconText: "cancel", iconType: "FONT_MATERIAL" },
    { iconText: "close", iconType: "FONT_MATERIAL" },
    { iconText: "zoom_in_map", iconType: "FONT_MATERIAL" },
  ] as ActionIconTypeSelection,

  FULL_ICON_TEXT: "more_horiz",
};

export const ActionType = {
  INITIAL: {
    actionType: "PHONE",
    actionValue: "",
    backgroundColor: "#009900",
    iconColor: "#FFFFFF",
    iconText: "call",
    iconType: "FONT",
    iconEnabled: true,
    labelText: "",
    schedule: {
      showAlways: true,
      daysOfWeek: Array(7).fill(false),
      start: "00:00",
      stop: "23:59",
      timezone: "",
      outsideHours: false,
    },
    properties: {},
  } as Partial<Action>,

  TYPE_NAME: {
    PHONE: "💬 Phone",
    CHAT: "💬 Chat",
    EMAIL: "💬 Email",
    SMS: "💬 SMS/Text",
    WHATSAPP: "💬 WhatsApp",
    FACEBOOK: "💬 Messenger",
    SIGNAL: "💬 Signal",
    TELEGRAM: "💬 Telegram",
    ANCHOR: "⏬ Scroll to point",
    LINK: "🔗 Link",
    MAP: "📍 Location",
    TALLY: "🔌 Tally form window",
    IFRAME: "🔌 Content window",
    INTERCOM: "🔌 Intercom chat",
    LINE: "💬 Line",
    SKYPE: "💬 Skype",
    VIBER: "💬 Viber",
    WECHAT: "💬 WeChat",
    ZALO: "💬 Zalo",
  } as Record<Action["actionType"], string>,

  VALUE_LABEL: {
    ANCHOR: "Anchor name",
    CHAT: "Chat ID (unused)",
    EMAIL: "Email address",
    FACEBOOK: "Username / Page ID",
    IFRAME: "Internet address",
    INTERCOM: "App ID",
    LINE: "Line ID",
    LINK: "Internet address",
    MAP: "Full address",
    PHONE: "Phone number",
    SIGNAL: "Phone number",
    SKYPE: "Skype username",
    SMS: "Phone number",
    TALLY: "Tally form ID",
    TELEGRAM: "Username",
    VIBER: "Username",
    WECHAT: "User ID",
    WHATSAPP: "Phone number",
    ZALO: "Phonenumber, username or group name",
  } as Record<Action["actionType"], string>,

  VALUE_VALIDATION_MESSAGE: {
    ANCHOR: "Please enter the name of an anchor on the page",
    EMAIL: "Please enter an email address",
    FACEBOOK: "Please enter a Facebook username / Page ID",
    IFRAME: "Please enter a full url starting with http...",
    INTERCOM: "Please enter your Intercom App ID",
    LINE: "Please enter your Line ID",
    LINK: "Please enter a full url starting with http...",
    MAP: "Please enter a full address",
    PHONE: "Please enter a phone number",
    SIGNAL: "Please enter a phone number",
    SKYPE: "Please enter your Skype username or number",
    SMS: "Please enter a phone number",
    TALLY: "Please enter your Tally form ID",
    TELEGRAM: "Please enter a Telegram username",
    VIBER: "Please enter your Viber username",
    WECHAT: "Please enter your WeChat user ID",
    WHATSAPP: "Please enter a phone number",
    ZALO: "Please enter your (Zalo) phonenumber, username or group name",
  } as Record<Action["actionType"], string>,

  VALUE_INPUT_TYPE: {
    EMAIL: "email",
    IFRAME: "url",
    LINK: "url",
    PHONE: "tel",
    SIGNAL: "tel",
    SMS: "tel",
    WHATSAPP: "tel",
  } as Partial<Record<Action["actionType"], string>>,

  VALUE_HIDDEN: {
    CHAT: true,
  } as Partial<Record<Action["actionType"], boolean>>,

  ICON_TEXT: {
    ANCHOR: ["anchor", "close_down", "anchor_up"],
    CHAT: ["chat"],
    EMAIL: ["email", "mail2", "mail3"],
    FACEBOOK: ["facebook_messenger"],
    IFRAME: [
      "open_modal",
      "calendar",
      "communicate",
      "conversation",
      "more_info",
      "call_back",
      "donate",
      "payment",
    ],
    INTERCOM: ["intercom"],
    LINE: ["line"],
    LINK: ["link", "link2", "link3", "link4", "link5", "calendar"],
    MAP: [
      "directions",
      "directions2",
      "directions3",
      "directions4",
      "directions5",
      "directions6",
    ],
    PHONE: ["call", "call2", "call3", "call4"],
    SIGNAL: ["signal"],
    SKYPE: ["skype"],
    SMS: ["chat", "sms"],
    TALLY: [
      "call3",
      "email",
      "chat",
      "communicate",
      "open_modal",
      "donate",
      "payment",
    ],
    TELEGRAM: ["telegram"],
    VIBER: ["viber"],
    WECHAT: ["wechat"],
    WHATSAPP: ["whatsapp"],
    ZALO: ["zalo"],
  } as Record<Action["actionType"], string[]>,

  DAY_NAMES: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],

  WHATSAPP_DIALOG_TRUTHY: "popout",

  LINK_TARGET_OPTIONS: {
    _blank: "New window",
    _self: "Same window",
  } as const,

  MAP_QUERY_OPTIONS: {
    q: "Show location",
    daddr: "Show travel directions",
  } as const,

  /**
   * For the client, "modal-height" can be any valid unit (vh, %, rem, etc),
   * but to keep it consist, we allow only a few pre-defined values to be set.
   */
  MODAL_HEIGHT_REGEXP: /^(\d*(?:\.\d+)?)((?:px|vh)?)/,
  MODAL_HEIGHT_UNIT_VALUES: ["px", "vh"] as const,

  /**
   * For the client, these values can be any valid unit (99vw, 100%, etc),
   * but to keep it consist, we have opted for a few
   * pre-defined values.
   */
  MODAL_WIDTH_OPTIONS: {
    "400px": "Normal",
    "250px": "Slim",
    "500px": "Wide",
    "600px": "Extra Wide",
  } as const,

  INTERCOM_ALIGNMENT_OPTIONS: {
    0: "Left side",
    1: "Right side",
  } as const,

  TALLY_HIDE_TITLE_OPTIONS: {
    0: "Show",
    1: "Hide",
  } as const,

  TALLY_ALIGN_LEFT_OPTIONS: {
    0: "Tally's default",
    1: "Left (recommended)",
  } as const,

  FACEBOOK_MESSENGER_WIDGET_DEFAULT_STATE: {
    open: "Start with Widget open (visible)",
    closed: "Start with Widget closed",
  },
  SKYPE_LINK_TYPE: {
    SKYPE: "Phone or chat",
    // INVITE: "Invite URL",
    // CONFERENCE: "Conference call",
  },
  SKYPE_PARAM_TYPE: {
    CALL: "Call",
    CHAT: "Open chat",
    // ADD: "Add contact",
    // SENDFILE: "Send a file",
    // USERINFO: "Open profile",
  },
  ZALO_LINK_TYPE: {
    PERSONAL: "Personal profile (default)",
    GROUP: "Group profile",
  },
  VIBER_LINK_TYPE: {
    PA_CHAT: "Business Chat (bot)",
    CHAT: "Personal Chat (phonenumber)",
    // PA_INFO: "Profile",
    // FORWARD: "Forward message",
    // LANDING_PAGE: "Open bot landing page",
    // ADD_NUMBER: "Add contact",
    // GROUP_INVITE: "Group invite",
    // GROUP2_INVITE: "Private group invite",
  },
  LINE_LINK_TYPE: {
    MESSAGE: "Chat",
    // PROFILE: "Open Line Profile",
  },
  WECHAT_LINK_TYPE: {
    CHAT: "Chat",
    WEIXIN_CHAT: "Weixin (only works on mobile devices)",
  },
};

export const ConditionType = {
  INITIAL: {
    conditionType: "URL",
    filterType: "INCLUDE",
    matchType: "EXACT",
    matchValue: "",
  } as Condition,

  FILTER_NAME: {
    INCLUDE: "Show button when",
    EXCLUDE: "Hide button when",
  } as Record<Condition["filterType"], string>,

  MATCH_NAME: {
    EXACT: "Page URL is:",
    SUBSTRING: "Page URL contains:",
    SIMPLE: "Page path starts with:",
    REGEX: "Page URL matches RegEx:",
    COUNTRY_CODE: "Country code is:",
    AGENTS_AVAILABLE: "Chat agents are available",
  } as Record<Condition["matchType"], string>,
};
