import { FormField } from "../../common";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import useSettings from "../../common/settings/useSettings";

const StorageSection: React.FC = () => {
  const settings = useSettings();
  // DELETE after R2 is rolled out everywhere
  const disableForNow = true;

  if (!settings.storageImplementation || disableForNow) return <></>;

  const handleStorageChange = async () => {
    await settings.updateStorageImplementation(newStorageImplementation);
  };

  const newStorageImplementation =
    settings.storageImplementation === "GCS" ? "R2" : "GCS";

  return (
    <FormField label="Storage">
      <Typography>
        Current storage used: {settings.storageImplementation}
      </Typography>
      <Link component="button" onClick={handleStorageChange}>
        Switch to {newStorageImplementation}
      </Link>
    </FormField>
  );
};

export default StorageSection;
