/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiServerExceptionResponse,
  Apikey,
  ApikeyCreateRequest,
  ApikeyDeleteResponse,
  ApikeyUpdateRequest,
  ApikeyWithKey,
} from '../models/index';
import {
    ApiServerExceptionResponseFromJSON,
    ApiServerExceptionResponseToJSON,
    ApikeyFromJSON,
    ApikeyToJSON,
    ApikeyCreateRequestFromJSON,
    ApikeyCreateRequestToJSON,
    ApikeyDeleteResponseFromJSON,
    ApikeyDeleteResponseToJSON,
    ApikeyUpdateRequestFromJSON,
    ApikeyUpdateRequestToJSON,
    ApikeyWithKeyFromJSON,
    ApikeyWithKeyToJSON,
} from '../models/index';

export interface ApikeyApiCreate5Request {
    apikeyCreateRequest: ApikeyCreateRequest;
}

export interface ApikeyApiDelete4Request {
    apikeyId: string;
}

export interface ApikeyApiGetByOneTimeTokenRequest {
    oneTimeToken: string;
}

export interface ApikeyApiGet5Request {
    apikeyId: string;
}

export interface ApikeyApiUpdate4Request {
    apikeyId: string;
    apikeyUpdateRequest: ApikeyUpdateRequest;
}

/**
 * 
 */
export class ApikeyApi extends runtime.BaseAPI {

    /**
     * Create a new `Apikey`. Also returns the actual API key (which cannot be retrieved again later).
     */
    async createRaw(requestParameters: ApikeyApiCreate5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApikeyWithKey>> {
        if (requestParameters['apikeyCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'apikeyCreateRequest',
                'Required parameter "apikeyCreateRequest" was null or undefined when calling create().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/apikey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApikeyCreateRequestToJSON(requestParameters['apikeyCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApikeyWithKeyFromJSON(jsonValue));
    }

    /**
     * Create a new `Apikey`. Also returns the actual API key (which cannot be retrieved again later).
     */
    async create(requestParameters: ApikeyApiCreate5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApikeyWithKey> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an Apikey for the current User
     */
    async deleteRaw(requestParameters: ApikeyApiDelete4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApikeyDeleteResponse>> {
        if (requestParameters['apikeyId'] == null) {
            throw new runtime.RequiredError(
                'apikeyId',
                'Required parameter "apikeyId" was null or undefined when calling delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/apikey/{apikeyId}`.replace(`{${"apikeyId"}}`, encodeURIComponent(String(requestParameters['apikeyId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApikeyDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Delete an Apikey for the current User
     */
    async delete(requestParameters: ApikeyApiDelete4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApikeyDeleteResponse> {
        const response = await this.deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all API keys without exposing the actual key(s) associated with them.
     * Retrieve the existing created API keys.
     */
    async getAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Apikey>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/apikey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApikeyFromJSON));
    }

    /**
     * Retrieves all API keys without exposing the actual key(s) associated with them.
     * Retrieve the existing created API keys.
     */
    async getAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Apikey>> {
        const response = await this.getAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * The only way to get a OTT is via the WordPress signup (/v1/user/wp), which includes the OTT in the email.
     * Retrieve an API key by a single-use token. This token is generally only valid for 1 hour.
     */
    async getByOneTimeTokenRaw(requestParameters: ApikeyApiGetByOneTimeTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApikeyWithKey>> {
        if (requestParameters['oneTimeToken'] == null) {
            throw new runtime.RequiredError(
                'oneTimeToken',
                'Required parameter "oneTimeToken" was null or undefined when calling getByOneTimeToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/apikey/ott/{oneTimeToken}`.replace(`{${"oneTimeToken"}}`, encodeURIComponent(String(requestParameters['oneTimeToken']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApikeyWithKeyFromJSON(jsonValue));
    }

    /**
     * The only way to get a OTT is via the WordPress signup (/v1/user/wp), which includes the OTT in the email.
     * Retrieve an API key by a single-use token. This token is generally only valid for 1 hour.
     */
    async getByOneTimeToken(requestParameters: ApikeyApiGetByOneTimeTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApikeyWithKey> {
        const response = await this.getByOneTimeTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an `Apikey` by its ID
     */
    async getRaw(requestParameters: ApikeyApiGet5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Apikey>> {
        if (requestParameters['apikeyId'] == null) {
            throw new runtime.RequiredError(
                'apikeyId',
                'Required parameter "apikeyId" was null or undefined when calling get().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/apikey/{apikeyId}`.replace(`{${"apikeyId"}}`, encodeURIComponent(String(requestParameters['apikeyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApikeyFromJSON(jsonValue));
    }

    /**
     * Get an `Apikey` by its ID
     */
    async get(requestParameters: ApikeyApiGet5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Apikey> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an `Apikey`. Currently only the name can be updated.
     */
    async updateRaw(requestParameters: ApikeyApiUpdate4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Apikey>> {
        if (requestParameters['apikeyId'] == null) {
            throw new runtime.RequiredError(
                'apikeyId',
                'Required parameter "apikeyId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['apikeyUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'apikeyUpdateRequest',
                'Required parameter "apikeyUpdateRequest" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-cnb-api-key"] = await this.configuration.apiKey("x-cnb-api-key"); // apikeyScheme authentication
        }

        const response = await this.request({
            path: `/v1/apikey/{apikeyId}`.replace(`{${"apikeyId"}}`, encodeURIComponent(String(requestParameters['apikeyId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApikeyUpdateRequestToJSON(requestParameters['apikeyUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApikeyFromJSON(jsonValue));
    }

    /**
     * Update an `Apikey`. Currently only the name can be updated.
     */
    async update(requestParameters: ApikeyApiUpdate4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Apikey> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
