import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { DomainType } from "../domainModels";
import { DomainContext } from "../useDomain";
import { Domain } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
  },
  error: {
    backgroundColor: alpha(theme.palette.error.main, 0.3),
    borderColor: alpha(theme.palette.error.main, 0.4),
  },
  pending: {
    backgroundColor: alpha(theme.palette.warning.main, 0.3),
    borderColor: alpha(theme.palette.warning.main, 0.4),
  },
  success: {
    backgroundColor: alpha(theme.palette.success.main, 0.3),
    borderColor: alpha(theme.palette.success.main, 0.4),
  },
}));

const success = (domain: Domain | undefined) => {
  if (!domain) return null;

  switch (domain.type) {
    case "STARTER":
    case "FREE":
      return `Thank you for your purchase! The domain ${domain.name} is being upgraded to Pro.`;
    case "PRO":
      return `Thank you for your purchase! The domain ${
        domain.name
      } has been upgraded to ${DomainType.TYPE_NAME[domain.type]}.`;
  }
};

const pending = (domain: Domain | undefined) => {
  if (!domain) return "Your payment is being processed.";
  return `Thank you for your purchase! The domain ${domain.name} is being upgraded to Pro.`;
};

type ResultType = {
  status: "success" | "cancelled" | "pending" | null;
  domain?: Domain | undefined;
};

const message = (res: ResultType) => {
  switch (res.status) {
    case "cancelled":
      return "Your payment was cancelled.";
    case "pending":
      return pending(res.domain);
    case "success":
      return success(res.domain);
    default:
      return null;
  }
};

const PaymentNote: React.FC = () => {
  const { classes, cx } = useStyles();
  const { domains, paymentResult } = React.useContext(DomainContext);
  const res: ResultType = !domains
    ? { status: null }
    : paymentResult.status === "success" || paymentResult.status === "pending"
      ? {
          status: paymentResult.status,
          domain: domains[paymentResult.domainId],
        }
      : { status: paymentResult.status };

  /**
   * If there is no status, return nothing
   */
  if (!res.status) return <></>;

  return (
    <Typography
      className={cx(classes.root, {
        [classes.error]: res.status === "cancelled",
        [classes.pending]: res.status === "pending",
        [classes.success]: res.status === "success",
      })}
    >
      {message(res)}
    </Typography>
  );
};

export default PaymentNote;
