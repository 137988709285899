import React from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { ActionType } from "../../buttonModels";

const TallySettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  return (
    <ExpansionPanel title={"Tally settings"}>
      <FormGroup>
        <TextField
          label="Form title"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.tally-hide-title`,
          )}
        >
          {Object.entries(ActionType.TALLY_HIDE_TITLE_OPTIONS).map(
            ([value, name]) => (
              <option key={value} value={value}>
                {name}
              </option>
            ),
          )}
        </TextField>
        <TextField
          label="Content alignment"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.tally-align-left`,
          )}
        >
          {Object.entries(ActionType.TALLY_ALIGN_LEFT_OPTIONS).map(
            ([value, name]) => (
              <option key={value} value={value}>
                {name}
              </option>
            ),
          )}
        </TextField>
      </FormGroup>
    </ExpansionPanel>
  );
};

export default TallySettings;
