/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultiButtonOptions
 */
export interface MultiButtonOptions {
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconBackgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    labelTextOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    labelTextColorOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    labelBackgroundColorOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    labelTextClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    labelTextColorClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    labelBackgroundColorClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconTypeOpen?: MultiButtonOptionsIconTypeOpenEnum;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconTextOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconBackgroundImageOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconClassOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconColorOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconBackgroundColorOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconEnabledOpen?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconTypeClose?: MultiButtonOptionsIconTypeCloseEnum;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconTextClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconBackgroundImageClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconClassClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconColorClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconBackgroundColorClose?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiButtonOptions
     */
    iconEnabledClose?: string;
}


/**
 * @export
 */
export const MultiButtonOptionsIconTypeOpenEnum = {
    Default: 'DEFAULT',
    Custom: 'CUSTOM',
    Font: 'FONT',
    Svg: 'SVG',
    FontMaterial: 'FONT_MATERIAL'
} as const;
export type MultiButtonOptionsIconTypeOpenEnum = typeof MultiButtonOptionsIconTypeOpenEnum[keyof typeof MultiButtonOptionsIconTypeOpenEnum];

/**
 * @export
 */
export const MultiButtonOptionsIconTypeCloseEnum = {
    Default: 'DEFAULT',
    Custom: 'CUSTOM',
    Font: 'FONT',
    Svg: 'SVG',
    FontMaterial: 'FONT_MATERIAL'
} as const;
export type MultiButtonOptionsIconTypeCloseEnum = typeof MultiButtonOptionsIconTypeCloseEnum[keyof typeof MultiButtonOptionsIconTypeCloseEnum];


/**
 * Check if a given object implements the MultiButtonOptions interface.
 */
export function instanceOfMultiButtonOptions(value: object): value is MultiButtonOptions {
    return true;
}

export function MultiButtonOptionsFromJSON(json: any): MultiButtonOptions {
    return MultiButtonOptionsFromJSONTyped(json, false);
}

export function MultiButtonOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiButtonOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'iconBackgroundColor': json['iconBackgroundColor'] == null ? undefined : json['iconBackgroundColor'],
        'iconColor': json['iconColor'] == null ? undefined : json['iconColor'],
        'labelTextOpen': json['labelTextOpen'] == null ? undefined : json['labelTextOpen'],
        'labelTextColorOpen': json['labelTextColorOpen'] == null ? undefined : json['labelTextColorOpen'],
        'labelBackgroundColorOpen': json['labelBackgroundColorOpen'] == null ? undefined : json['labelBackgroundColorOpen'],
        'labelTextClose': json['labelTextClose'] == null ? undefined : json['labelTextClose'],
        'labelTextColorClose': json['labelTextColorClose'] == null ? undefined : json['labelTextColorClose'],
        'labelBackgroundColorClose': json['labelBackgroundColorClose'] == null ? undefined : json['labelBackgroundColorClose'],
        'iconTypeOpen': json['iconTypeOpen'] == null ? undefined : json['iconTypeOpen'],
        'iconTextOpen': json['iconTextOpen'] == null ? undefined : json['iconTextOpen'],
        'iconBackgroundImageOpen': json['iconBackgroundImageOpen'] == null ? undefined : json['iconBackgroundImageOpen'],
        'iconClassOpen': json['iconClassOpen'] == null ? undefined : json['iconClassOpen'],
        'iconColorOpen': json['iconColorOpen'] == null ? undefined : json['iconColorOpen'],
        'iconBackgroundColorOpen': json['iconBackgroundColorOpen'] == null ? undefined : json['iconBackgroundColorOpen'],
        'iconEnabledOpen': json['iconEnabledOpen'] == null ? undefined : json['iconEnabledOpen'],
        'iconTypeClose': json['iconTypeClose'] == null ? undefined : json['iconTypeClose'],
        'iconTextClose': json['iconTextClose'] == null ? undefined : json['iconTextClose'],
        'iconBackgroundImageClose': json['iconBackgroundImageClose'] == null ? undefined : json['iconBackgroundImageClose'],
        'iconClassClose': json['iconClassClose'] == null ? undefined : json['iconClassClose'],
        'iconColorClose': json['iconColorClose'] == null ? undefined : json['iconColorClose'],
        'iconBackgroundColorClose': json['iconBackgroundColorClose'] == null ? undefined : json['iconBackgroundColorClose'],
        'iconEnabledClose': json['iconEnabledClose'] == null ? undefined : json['iconEnabledClose'],
    };
}

  export function MultiButtonOptionsToJSON(json: any): MultiButtonOptions {
      return MultiButtonOptionsToJSONTyped(json, false);
  }

  export function MultiButtonOptionsToJSONTyped(value?: MultiButtonOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'iconBackgroundColor': value['iconBackgroundColor'],
        'iconColor': value['iconColor'],
        'labelTextOpen': value['labelTextOpen'],
        'labelTextColorOpen': value['labelTextColorOpen'],
        'labelBackgroundColorOpen': value['labelBackgroundColorOpen'],
        'labelTextClose': value['labelTextClose'],
        'labelTextColorClose': value['labelTextColorClose'],
        'labelBackgroundColorClose': value['labelBackgroundColorClose'],
        'iconTypeOpen': value['iconTypeOpen'],
        'iconTextOpen': value['iconTextOpen'],
        'iconBackgroundImageOpen': value['iconBackgroundImageOpen'],
        'iconClassOpen': value['iconClassOpen'],
        'iconColorOpen': value['iconColorOpen'],
        'iconBackgroundColorOpen': value['iconBackgroundColorOpen'],
        'iconEnabledOpen': value['iconEnabledOpen'],
        'iconTypeClose': value['iconTypeClose'],
        'iconTextClose': value['iconTextClose'],
        'iconBackgroundImageClose': value['iconBackgroundImageClose'],
        'iconClassClose': value['iconClassClose'],
        'iconColorClose': value['iconColorClose'],
        'iconBackgroundColorClose': value['iconBackgroundColorClose'],
        'iconEnabledClose': value['iconEnabledClose'],
    };
}

