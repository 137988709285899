/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DomainUpdateRequest
 */
export interface DomainUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DomainUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DomainUpdateRequest
     */
    name?: string;
    /**
     * As part of a Stripe Subscription (or checkout session), to indicate the payment interval.
     * @type {string}
     * @memberof DomainUpdateRequest
     */
    interval?: DomainUpdateRequestIntervalEnum;
    /**
     * 
     * @type {Date}
     * @memberof DomainUpdateRequest
     */
    expires?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DomainUpdateRequest
     */
    renew?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainUpdateRequest
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DomainUpdateRequest
     */
    trackGA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DomainUpdateRequest
     */
    trackConversion?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainUpdateRequest
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DomainUpdateRequest
     */
    properties?: { [key: string]: string; };
}


/**
 * @export
 */
export const DomainUpdateRequestIntervalEnum = {
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type DomainUpdateRequestIntervalEnum = typeof DomainUpdateRequestIntervalEnum[keyof typeof DomainUpdateRequestIntervalEnum];


/**
 * Check if a given object implements the DomainUpdateRequest interface.
 */
export function instanceOfDomainUpdateRequest(value: object): value is DomainUpdateRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function DomainUpdateRequestFromJSON(json: any): DomainUpdateRequest {
    return DomainUpdateRequestFromJSONTyped(json, false);
}

export function DomainUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'interval': json['interval'] == null ? undefined : json['interval'],
        'expires': json['expires'] == null ? undefined : (new Date(json['expires'])),
        'renew': json['renew'] == null ? undefined : json['renew'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'trackGA': json['trackGA'] == null ? undefined : json['trackGA'],
        'trackConversion': json['trackConversion'] == null ? undefined : json['trackConversion'],
        'aliases': json['aliases'] == null ? undefined : json['aliases'],
        'properties': json['properties'] == null ? undefined : json['properties'],
    };
}

  export function DomainUpdateRequestToJSON(json: any): DomainUpdateRequest {
      return DomainUpdateRequestToJSONTyped(json, false);
  }

  export function DomainUpdateRequestToJSONTyped(value?: DomainUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'interval': value['interval'],
        'expires': value['expires'] == null ? undefined : ((value['expires']).toISOString()),
        'renew': value['renew'],
        'timezone': value['timezone'],
        'trackGA': value['trackGA'],
        'trackConversion': value['trackConversion'],
        'aliases': value['aliases'],
        'properties': value['properties'],
    };
}

