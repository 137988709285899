import { useEffect, useState } from "react";
import { api as prodApi, demoApi } from "./settingsApi";
import { useAuth } from "../../auth";
import { Settings, SettingsOptions } from "../../lib/cnb/api/public";
import { isDev } from "../../lib/cnb/env";

const api = isDev ? demoApi : prodApi;

const useSettings = () => {
  const [settings, setSettings] = useState<Settings | undefined>(undefined);
  const [storageImplementation, setStorageImplementation] = useState<
    SettingsOptions["storageType"] | undefined
  >(undefined);

  //const auth = useAuth();
  const auth = useAuth();
  useEffect(() => {
    api
      .getSettings(auth.currentUser)
      .then((settings) => {
        setSettings(settings);
        setStorageImplementation(settings.options.storageType);
      })
      .catch(() => {}); // NOOP
  }, [auth]);

  const updateStorageImplementation = async (
    storageImplementation: SettingsOptions["storageType"],
  ) => {
    await auth.setStorageImplementation(storageImplementation);
    setStorageImplementation(storageImplementation);
  };

  return {
    settings,
    storageImplementation,
    updateStorageImplementation,
  };
};

export default useSettings;
