import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ExpansionPanel, FormField, ProFeature } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { DomainContext } from "../../../domain";
import { ActionType } from "../../buttonModels";

const FacebookSettings: React.FC<ActionPropsProps> = ({
  formal,
  pathPrefix,
}) => {
  const isFacebookWidget =
    formal.getValue(`${pathPrefix}properties.facebook-dialog-type`) ===
    "widget";

  const { currentDomain } = React.useContext(DomainContext);

  return (
    <ExpansionPanel title={"Facebook Settings"}>
      <FormGroup>
        <FormField>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                {...formal.getCheckboxProps(
                  `${pathPrefix}properties.facebook-dialog-type`,
                )}
                disabled={currentDomain?.type !== "PRO"}
                checked={isFacebookWidget}
                onChange={(_: any, ck: boolean) =>
                  formal.setValue(
                    `${pathPrefix}properties.facebook-dialog-type`,
                    ck ? "widget" : "",
                  )
                }
              />
            }
            label="Messenger Widget"
          />
          {currentDomain?.type !== "PRO" && (
            <ProFeature featureName={"The Messenger Widget is"} />
          )}
        </FormField>
        {isFacebookWidget && (
          <>
            <TextField
              label="Default widget state"
              select
              SelectProps={{
                native: true,
              }}
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.facebook-widget-default-state`,
              )}
            >
              {Object.entries(
                ActionType.FACEBOOK_MESSENGER_WIDGET_DEFAULT_STATE,
              ).map(([value, name]) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </TextField>
            <TextField
              label="App ID (optional)"
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.facebook-widget-app-id`,
              )}
            />
          </>
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default FacebookSettings;
