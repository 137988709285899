/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A Stripe plan, which is a subscription plan that can be used to create a subscription.
 * @export
 * @interface StripePlan
 */
export interface StripePlan {
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    domainType: StripePlanDomainTypeEnum;
    /**
     * A Stripe currency supported by CNB.
     * @type {string}
     * @memberof StripePlan
     */
    currency: StripePlanCurrencyEnum;
    /**
     * As part of a Stripe Subscription (or checkout session), to indicate the payment interval.
     * @type {string}
     * @memberof StripePlan
     */
    interval: StripePlanIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof StripePlan
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof StripePlan
     */
    trialPeriodDays: number;
}


/**
 * @export
 */
export const StripePlanDomainTypeEnum = {
    Free: 'FREE',
    Pro: 'PRO',
    Starter: 'STARTER'
} as const;
export type StripePlanDomainTypeEnum = typeof StripePlanDomainTypeEnum[keyof typeof StripePlanDomainTypeEnum];

/**
 * @export
 */
export const StripePlanCurrencyEnum = {
    Eur: 'EUR',
    Usd: 'USD'
} as const;
export type StripePlanCurrencyEnum = typeof StripePlanCurrencyEnum[keyof typeof StripePlanCurrencyEnum];

/**
 * @export
 */
export const StripePlanIntervalEnum = {
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type StripePlanIntervalEnum = typeof StripePlanIntervalEnum[keyof typeof StripePlanIntervalEnum];


/**
 * Check if a given object implements the StripePlan interface.
 */
export function instanceOfStripePlan(value: object): value is StripePlan {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('nickname' in value) || value['nickname'] === undefined) return false;
    if (!('domainType' in value) || value['domainType'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('interval' in value) || value['interval'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('trialPeriodDays' in value) || value['trialPeriodDays'] === undefined) return false;
    return true;
}

export function StripePlanFromJSON(json: any): StripePlan {
    return StripePlanFromJSONTyped(json, false);
}

export function StripePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePlan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nickname': json['nickname'],
        'domainType': json['domainType'],
        'currency': json['currency'],
        'interval': json['interval'],
        'price': json['price'],
        'trialPeriodDays': json['trialPeriodDays'],
    };
}

  export function StripePlanToJSON(json: any): StripePlan {
      return StripePlanToJSONTyped(json, false);
  }

  export function StripePlanToJSONTyped(value?: StripePlan | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'nickname': value['nickname'],
        'domainType': value['domainType'],
        'currency': value['currency'],
        'interval': value['interval'],
        'price': value['price'],
        'trialPeriodDays': value['trialPeriodDays'],
    };
}

