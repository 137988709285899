import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { ActionType } from "../../buttonModels";

const WechatSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  return (
    <ExpansionPanel title="WeChat Settings">
      <FormGroup>
        <TextField
          label="Type"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.wechat-link-type`,
          )}
        >
          {Object.entries(ActionType.WECHAT_LINK_TYPE).map(([value, name]) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </TextField>
      </FormGroup>
    </ExpansionPanel>
  );
};

export default WechatSettings;
