import React from "react";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { ActionType } from "../../buttonModels";

const IntercomSettings: React.FC<ActionPropsProps> = ({
  formal,
  pathPrefix,
}) => {
  return (
    <ExpansionPanel title={"Intercom settings"}>
      <FormGroup>
        <TextField
          label="Intercom window placement"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.intercom-alignment`,
          )}
        >
          {Object.entries(ActionType.INTERCOM_ALIGNMENT_OPTIONS).map(
            ([value, name]) => (
              <option key={value} value={value}>
                {name}
              </option>
            ),
          )}
        </TextField>
      </FormGroup>
    </ExpansionPanel>
  );
};

export default IntercomSettings;
