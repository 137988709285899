import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { ChromePicker } from "react-color";

const useStyles = makeStyles()((theme) => ({
  inputUnderline: {
    "&:before, &:after": {
      right: 38,
    },
  },
  circle: {
    boxShadow: theme.shadows[1],
    padding: 19,
    marginBottom: 11,
  },
}));

const ColorPicker: React.FC<
  TextFieldProps & {
    value?: string;
    defaultValue?: string;
    onChange?: (event: { target: { value: string } }) => void;
  }
> = (props) => {
  const { classes } = useStyles();
  const [pickerAnchor, setPickerAnchor] = React.useState<null | HTMLElement>(
    null,
  );
  return (
    <>
      <TextField
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={(event) => setPickerAnchor(event.currentTarget)}
                className={classes.circle}
                style={{
                  backgroundColor: props.value || props.defaultValue,
                }}
                size="large"
              />
            </InputAdornment>
          ),
          classes: {
            underline: classes.inputUnderline,
          } as any,
        }}
        InputLabelProps={{ shrink: !!(props.value || props.defaultValue) }}
      />
      <Popover
        open={Boolean(pickerAnchor)}
        anchorEl={pickerAnchor}
        onClose={() => setPickerAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ChromePicker
          color={props.value || props.defaultValue}
          onChange={(value) => {
            props.onChange?.({ target: { value: value.hex } });
          }}
          disableAlpha
        />
      </Popover>
    </>
  );
};

export default ColorPicker;
