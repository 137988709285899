import React, { useContext } from "react";
import { DomainContext } from "../useDomain";
import { Alert, Link, Snackbar } from "@mui/material";
import Chip from "@mui/material/Chip";

interface OutstandingPaymentNoteProps {
  snackbar?: boolean;
}

const OutstandingPaymentNote = ({
  snackbar = true,
}: OutstandingPaymentNoteProps) => {
  const { currentDomain, wpInfo } = useContext(DomainContext);
  const data = wpInfo.wpInfo?.subscriptionStatusData;

  /**
   * If there is no outstanding payment, return nothing
   */
  if (!data?.invoiceUrl) return <></>;

  const alert = (
    <Alert severity="error" sx={{ width: "100%" }}>
      <strong>Payment failed!</strong> Your{" "}
      <Chip
        sx={{ fontWeight: "bold" }}
        label="PRO"
        color="primary"
        size="small"
      />{" "}
      subscription for {currentDomain?.name} is currently{" "}
      <strong>paused</strong> as we were unable to collect the subscription fee
      of {wpInfo.getFormattedAmount()}. Please remit payment at your earliest
      convenience.{" "}
      <Chip
        sx={{ fontWeight: "bold" }}
        label="PRO"
        color="primary"
        size="small"
      />{" "}
      features are reactivated once the balance has been settled.{" "}
      <Link href={data.invoiceUrl} target="_blank" rel="noreferrer">
        Pay now!
      </Link>
    </Alert>
  );

  return snackbar ? (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      {alert}
    </Snackbar>
  ) : (
    <>{alert}</>
  );
};

export default OutstandingPaymentNote;
