import React from "react";
import { DomainContext } from "../../domain";
import { ExtendedFormState, ProFeature } from "../../common";
import { ButtonType } from "../buttonModels";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import { Action, Button } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  proFeature: {
    marginTop: theme.spacing(1),
  },
}));

const AnimationChoice: React.FC<{
  formal: ExtendedFormState<Button | Action>;
}> = ({ formal }) => {
  const { classes } = useStyles();
  const { currentDomain } = React.useContext(DomainContext);
  const isFull = "type" in formal.values && formal.values.type === "FULL";

  if (isFull) return <></>;

  if (currentDomain?.type === "STARTER") {
    return (
      <Box className={classes.proFeature}>
        <ProFeature featureName={"Button animations are"} />
      </Box>
    );
  }

  return (
    <TextField
      label="Button animation"
      select
      SelectProps={{
        native: true,
      }}
      {...formal.getTextFieldProps("options.animation")}
    >
      {Object.entries(ButtonType.ANIMATION_NAME).map(([action, name]) => (
        <option key={action} value={action}>
          {name}
        </option>
      ))}
    </TextField>
  );
};

export default AnimationChoice;
