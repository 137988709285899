import React from "react";
import TextField from "@mui/material/TextField";
import { ActionType } from "../../buttonModels";
import { ActionPropsProps } from "../ActionProps";

const LinkSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  return (
    <TextField
      label="Open link in"
      select
      SelectProps={{
        native: true,
      }}
      {...formal.getTextFieldProps(`${pathPrefix}properties.link-target`)}
    >
      {Object.entries(ActionType.LINK_TARGET_OPTIONS).map(([value, name]) => (
        <option key={value} value={value}>
          {name}
        </option>
      ))}
    </TextField>
  );
};

export default LinkSettings;
