import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { routes, SpinnerContext, useExtendedForm } from "../common";
import { AuthContext } from "./useAuth";
import { AuthPage } from "./components";

const initialValues = {
  email: "",
};

const schema = yup
  .object<typeof initialValues>()
  .shape({
    email: yup.string().email().required("email is missing"),
  })
  .defined();

const ForgotPasswordPage: React.FC = () => {
  const { requestPasswordReset } = React.useContext(AuthContext);
  const { withSpinner } = React.useContext(SpinnerContext);
  const formal = useExtendedForm({
    initialValues,
    schema,
    onSubmit: (values) => withSpinner(requestPasswordReset(values.email)),
  });

  return (
    <AuthPage>
      <form {...formal.getFormProps()}>
        {formal.submitState.state !== "success" ? (
          <FormGroup>
            <Typography variant="h5" align="center" paragraph>
              Forgot password
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Enter your account email below so we can send you a password reset
              link.
            </Typography>
            <TextField
              label="Email address"
              type="email"
              {...formal.getTextFieldProps("email")}
            />
            <p>
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                {...formal.getSubmitButtonProps()}
                type="submit"
              >
                Reset Password
              </Button>
              {formal.submitState.state === "error" && (
                <FormHelperText error>
                  {formal.submitState.message ||
                    "Server error: reset email couldn't be sent"}
                </FormHelperText>
              )}
            </p>
            <Typography variant="body1" align="center">
              <Link component={RouterLink} to={routes.LOGIN.path}>
                Return to login
              </Link>
            </Typography>
          </FormGroup>
        ) : (
          <FormGroup>
            <Typography variant="h5" align="center" paragraph>
              Reset Email Sent!
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              If there's an account associated with that email address, you will
              receive an email with password reset instructions.
              <br />
              <br />
              If the email doesn't show up soon, check your spam folder. We sent
              it from hello@nowbuttons.com
            </Typography>
            <p>
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                component={RouterLink}
                to={routes.LOGIN.path}
              >
                Return To Login
              </Button>
            </p>
          </FormGroup>
        )}
      </form>
    </AuthPage>
  );
};

export default ForgotPasswordPage;
