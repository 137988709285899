import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { InitialPathContext, routes, useExtendedForm } from "../common";
import { AuthContext } from "./useAuth";
import { AuthPage } from "./components";

const PRIVACY_POLICY_URL = "https://nowbuttons.com/legal/privacy/";
const TERMS_CONDITIONS_URL = "https://nowbuttons.com/legal/terms/";

const initialValues = {
  email: "",
  password: "",
  password2: "",
  emailOptIn: false,
};

const schema = yup
  .object<typeof initialValues>()
  .shape({
    email: yup.string().email().required("email is missing"),
    password: yup
      .string()
      .min(9, "Passwords should be at least 9 characters.")
      .required(),
    password2: yup
      .string()
      .oneOf([yup.ref("password"), ""], "passwords don't match")
      .required("confirm password is missing"),
  })
  .defined();

const RegisterPage: React.FC = () => {
  const { register, login } = React.useContext(AuthContext);
  const { initialPath } = React.useContext(InitialPathContext);
  const formal = useExtendedForm({
    initialValues,
    schema,
    onSubmit: async (values) => {
      await register(
        values.email,
        values.password,
        values.emailOptIn,
        new URLSearchParams(
          initialPath?.substring(initialPath.indexOf("?")),
        ).get("utm_source") ?? "web",
      );
      await login(values.email, values.password, false);
    },
  });

  return (
    <AuthPage>
      <form {...formal.getFormProps()}>
        <FormGroup>
          <Typography variant="h5" align="center" paragraph>
            Get started for free!
          </Typography>
          <TextField
            label="Email address"
            type="email"
            {...formal.getTextFieldProps("email")}
          />
          <TextField
            label="Password"
            type="password"
            {...formal.getTextFieldProps("password")}
          />
          <TextField
            label="Confirm password"
            type="password"
            {...formal.getTextFieldProps("password2")}
          />
          <Typography variant="body2">
            By clicking the "Sign Up" button, you are creating a Call Now Button
            account, and you agree to our{" "}
            <Link target="_blank" href={TERMS_CONDITIONS_URL}>
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link target="_blank" href={PRIVACY_POLICY_URL}>
              Privacy Policy
            </Link>
            .
          </Typography>
          <p>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              {...formal.getSubmitButtonProps()}
              type="submit"
            >
              Sign Up
            </Button>
            {formal.submitState.state === "error" && (
              <FormHelperText error>
                {formal.submitState.message ||
                  "Server error: couldn't register this user"}
              </FormHelperText>
            )}
          </p>
          <Typography variant="body1" align="center">
            Already have an account?{" "}
            <Link component={RouterLink} to={routes.LOGIN.path}>
              Sign In
            </Link>
          </Typography>
        </FormGroup>
      </form>
    </AuthPage>
  );
};

export default RegisterPage;
