import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { ActionType } from "../../buttonModels";

const ViberSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  const isPaChatLinkType =
    !formal.getValue(`${pathPrefix}properties.viber-link-type`) ||
    formal.getValue(`${pathPrefix}properties.viber-link-type`) === "PA_CHAT";

  const isGroupInviteLinkType =
    formal.getValue(`${pathPrefix}properties.viber-link-type`) ===
      "GROUP_INVITE" ||
    formal.getValue(`${pathPrefix}properties.viber-link-type`) ===
      "GROUP2_INVITE";

  return (
    <ExpansionPanel title="Viber Settings">
      <FormGroup>
        <TextField
          label="Type"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.viber-link-type`,
          )}
        >
          {Object.entries(ActionType.VIBER_LINK_TYPE).map(([value, name]) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </TextField>
        {isPaChatLinkType && (
          <>
            <TextField
              label="Text"
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.viber-text`,
              )}
            />
            <TextField
              label="Content"
              {...formal.getTextFieldProps(
                `${pathPrefix}properties.viber-content`,
              )}
            />
          </>
        )}
        {isGroupInviteLinkType && (
          <TextField
            label="Language (optional, defaults to 'en')"
            {...formal.getTextFieldProps(`${pathPrefix}properties.viber-lang`)}
          />
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default ViberSettings;
