import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { routes } from "../index";

interface ProFeatureProps {
  featureName: string;
  plural?: boolean;
}

/**
 * Outputs a Marketing messages about "<featureName> a PRO feature. Upgrade".
 * @param {string} featureName "Custom icons are", etc
 * @param {boolean} plural
 */
const ProFeature: React.FC<ProFeatureProps> = ({
  featureName,
  plural = false,
}: ProFeatureProps) => {
  return (
    <Typography variant="body2" paragraph>
      {featureName} {!plural && "a"}{" "}
      <Chip
        sx={{ fontWeight: "bold" }}
        label="PRO"
        color="primary"
        size="small"
      />{" "}
      feature{plural && "s"}.{" "}
      <Link component={RouterLink} to={routes.DOMAIN_PLAN.path}>
        Upgrade
      </Link>
    </Typography>
  );
};

export default ProFeature;
