import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink, useHistory, Redirect } from "react-router-dom";
import * as yup from "yup";
import { routes, useExtendedForm } from "../common";
import { AuthContext } from "./useAuth";
import { AuthPage } from "./components";

const initialValues = {
  password: "",
  password2: "",
};

const schema = yup
  .object<typeof initialValues>()
  .shape({
    password: yup
      .string()
      .min(9, "Passwords should be at least 9 characters.")
      .required(),
    password2: yup
      .string()
      .oneOf([yup.ref("password"), ""], "passwords don't match")
      .required("confirm password is missing"),
  })
  .defined();

const ResetPasswordPage: React.FC = () => {
  const history = useHistory();
  const { resetPassword } = React.useContext(AuthContext);
  const token = new URLSearchParams(history.location.search).get("token");
  const formal = useExtendedForm({
    initialValues,
    schema,
    onSubmit: (values) => resetPassword(token || "error", values.password),
  });

  return (
    <AuthPage>
      <form {...formal.getFormProps()}>
        {!token ? (
          <Redirect to={routes.LOGIN.path} />
        ) : formal.submitState.state !== "success" ? (
          <FormGroup>
            <Typography variant="h5" align="center" paragraph>
              Reset your password
            </Typography>
            <Typography variant="body1" paragraph>
              Make sure you set a secure password that's at least 9 characters
              long
            </Typography>
            <TextField
              label="Password"
              type="password"
              {...formal.getTextFieldProps("password")}
            />
            <TextField
              label="Confirm password"
              type="password"
              {...formal.getTextFieldProps("password2")}
            />
            <p>
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                {...formal.getSubmitButtonProps()}
                type="submit"
              >
                Reset Password
              </Button>
              {formal.submitState.state === "error" && (
                <FormHelperText error>
                  {formal.submitState.message ||
                    "Server error: couldn't reset password."}
                </FormHelperText>
              )}
            </p>
          </FormGroup>
        ) : (
          <FormGroup>
            <Typography variant="h5" align="center" paragraph>
              Success!
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Your password was successfully updated!
            </Typography>
            <p>
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                component={RouterLink}
                to={routes.LOGIN.path}
              >
                Return To Login
              </Button>
            </p>
          </FormGroup>
        )}
      </form>
    </AuthPage>
  );
};

export default ResetPasswordPage;
