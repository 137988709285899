import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import KeyIcon from "@mui/icons-material/VpnKeyOutlined";
import React from "react";
import * as yup from "yup";
import {
  CopyIcon,
  SpinnerContext,
  useExtendedForm,
  withFormReset,
} from "../../common";
import { AuthContext } from "../useAuth";
import { ApikeyWithKey } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  tableTitle: {
    margin: theme.spacing(3, 0, 1),
  },
  table: {
    "& td": {
      color: theme.palette.text.secondary,
      borderBottomWidth: 0,
    },
    "& td:first-of-type": {
      paddingLeft: 0,
    },
    "&& td:last-child": {
      paddingRight: 0,
    },
  },
  nameCell: {
    overflowWrap: "anywhere",
  },
}));

const initialValues = {
  name: "",
};

const schema = yup
  .object<typeof initialValues>()
  .shape({
    name: yup.string().required("your API key requires a unique name"),
  })
  .defined();

const ApiKeys: React.FC<{ reset: () => void }> = ({ reset }) => {
  const { classes } = useStyles();
  const { apikeys, createApikey, deleteApikey } = React.useContext(AuthContext);
  const { withSpinner } = React.useContext(SpinnerContext);
  const [createdKey, setCreatedKey] = React.useState<ApikeyWithKey | null>(
    null,
  );
  const formal = useExtendedForm({
    initialValues,
    schema,
    onSubmit: async (values) => {
      const newKey = await withSpinner(createApikey(values.name));
      setCreatedKey(newKey);
    },
  });
  const handleCloseDialog = (_: any, reason?: string) => {
    if (reason === "backdropClick") return;
    reset();
  };
  const handleDelete = (id: string) => async () => {
    await withSpinner(deleteApikey(id));
  };
  const handleClipboard = (value: string) => async () => {
    await navigator.clipboard.writeText(value);
  };

  React.useEffect(() => {
    formal.setValue("name", `API Key ${(apikeys || []).length + 1}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apikeys]);

  return (
    <>
      <form {...formal.getFormProps()}>
        <TextField
          label="Name"
          {...formal.getTextFieldProps("name")}
          helperText={
            formal.errors["name"] ||
            (formal.submitState.state === "error" &&
              formal.submitState.message) ||
            "Recommendation: pick a name that helps you remember where you used the key"
          }
        />
        <Button
          variant="contained"
          color="primary"
          {...formal.getSubmitButtonProps()}
          type="submit"
        >
          Generate new API key
        </Button>
      </form>
      <Dialog
        open={!!createdKey}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Your API key for "{createdKey?.name}"</DialogTitle>
        <DialogContent>
          <TextField
            label="API key"
            autoFocus
            fullWidth
            spellCheck={false}
            value={createdKey?.key}
            helperText="This key is only displayed once. Copy the key and paste it directly inside the API key field of the Wordpress Call Now Button plugin."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClipboard(createdKey?.key!!)}
                    edge="end"
                    size="large"
                  >
                    <CopyIcon color="disabled" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="body2" className={classes.tableTitle}>
        Currently active API keys
      </Typography>
      <Table size="small" className={classes.table}>
        <TableBody>
          {(apikeys || [])
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((apikey) => (
              <TableRow key={apikey.id}>
                <TableCell padding="checkbox">
                  <KeyIcon />
                </TableCell>
                <TableCell className={classes.nameCell}>
                  {apikey.name}
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton onClick={handleDelete(apikey.id)} size="large">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default withFormReset(ApiKeys);
