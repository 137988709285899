/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CnbMarketingData
 */
export interface CnbMarketingData {
    /**
     * 
     * @type {string}
     * @memberof CnbMarketingData
     */
    signupSource?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CnbMarketingData
     */
    emailOptIn?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CnbMarketingData
     */
    emailOptInDate?: Date;
}

/**
 * Check if a given object implements the CnbMarketingData interface.
 */
export function instanceOfCnbMarketingData(value: object): value is CnbMarketingData {
    return true;
}

export function CnbMarketingDataFromJSON(json: any): CnbMarketingData {
    return CnbMarketingDataFromJSONTyped(json, false);
}

export function CnbMarketingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CnbMarketingData {
    if (json == null) {
        return json;
    }
    return {
        
        'signupSource': json['signupSource'] == null ? undefined : json['signupSource'],
        'emailOptIn': json['emailOptIn'] == null ? undefined : json['emailOptIn'],
        'emailOptInDate': json['emailOptInDate'] == null ? undefined : (new Date(json['emailOptInDate'])),
    };
}

  export function CnbMarketingDataToJSON(json: any): CnbMarketingData {
      return CnbMarketingDataToJSONTyped(json, false);
  }

  export function CnbMarketingDataToJSONTyped(value?: CnbMarketingData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'signupSource': value['signupSource'],
        'emailOptIn': value['emailOptIn'],
        'emailOptInDate': value['emailOptInDate'] == null ? undefined : ((value['emailOptInDate']).toISOString()),
    };
}

