import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OutstandingPaymentNote from "./components/OutstandingPaymentNote";
import Link from "@mui/material/Link";
import React, { useContext } from "react";
import { AuthContext } from "../auth";
import { DomainContext } from "./useDomain";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const OutstandingPaymentText = () => {
  const { openPaymentHistory } = React.useContext(AuthContext);
  const { currentDomain } = useContext(DomainContext);

  const handlePaymentHistory = async () => {
    try {
      await openPaymentHistory();
    } catch {
      window.alert("Can't connect to Stripe for payment history 😞");
    }
  };

  return (
    <Grid container alignItems="center" direction="column">
      <Typography variant="h4" align="center" paragraph>
        Outstanding payment
      </Typography>
      <Typography variant="subtitle1" align="center" paragraph>
        You have an outstanding payment for your domain{" "}
        <Box component="span" sx={{ fontWeight: "bold" }}>
          {currentDomain?.name}
        </Box>
        .
      </Typography>
      <Typography align="center" paragraph>
        Don't worry - Your buttons are safe and will not be modified.
      </Typography>
      <Typography align="center" paragraph>
        Until this is resolved, your subscription behaves as a Starter plan
        (meaning your site will not work with schedules, Multibuttons, etc).
      </Typography>
      <Typography align="center" paragraph>
        When the outstanding bill is payed, all buttons, schedules, etc are
        restored as soon the payment comes through and your subscription will be
        a{" "}
        <Chip
          sx={{ fontWeight: "bold" }}
          label="PRO"
          color="primary"
          size="small"
        />{" "}
        subscription again.
      </Typography>
      <OutstandingPaymentNote snackbar={false} />
      <Typography align="center" paragraph>
        If you want to change your tax details first or see your previous
        invoices, please use the Billing Portal:
        <Link component="button" variant="body1" onClick={handlePaymentHistory}>
          See payment history and invoices
        </Link>
      </Typography>
    </Grid>
  );
};

export default OutstandingPaymentText;
