import Drawer, { DrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

const useStyles = makeStyles()((theme, _, classes: any) => ({
  drawerPaper: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  right: {},
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [`&.${classes.right}`]: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
  },
  backButton: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5),
    color: alpha(theme.palette.primary.contrastText, 0.8),
    "&:hover": { color: theme.palette.primary.contrastText },
    [`.${classes.right} > &`]: {
      marginRight: 0,
      marginLeft: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(3, 2),
  },
}));

const RouteDrawer: React.FC<
  RouteProps & {
    title: React.ReactNode;
    drawerProps: DrawerProps;
    children: React.ReactNode;
  }
> = ({ title, drawerProps, children, ...routeProps }) => {
  const { classes, cx } = useStyles();
  return (
    <Route
      {...routeProps}
      children={(childrenProps) => (
        <Drawer
          open={Boolean(childrenProps.match)}
          {...drawerProps}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar
            className={cx(classes.toolbar, {
              [classes.right]: drawerProps.anchor === "right",
            })}
          >
            <IconButton
              onClick={() => childrenProps.history.goBack()}
              className={classes.backButton}
              size="large"
            >
              {drawerProps.anchor === "right" ? (
                <CloseIcon />
              ) : (
                <ArrowBackIcon />
              )}
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
          <div className={classes.content}>
            {childrenProps.match && children}
          </div>
        </Drawer>
      )}
    />
  );
};

export default RouteDrawer;
