/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutSessionStatusResponse
 */
export interface CheckoutSessionStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    checkoutSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    subscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSessionStatusResponse
     */
    trialPeriodDays: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    clientSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionStatusResponse
     */
    status: CheckoutSessionStatusResponseStatusEnum;
}


/**
 * @export
 */
export const CheckoutSessionStatusResponseStatusEnum = {
    Open: 'open',
    Complete: 'complete',
    Expired: 'expired'
} as const;
export type CheckoutSessionStatusResponseStatusEnum = typeof CheckoutSessionStatusResponseStatusEnum[keyof typeof CheckoutSessionStatusResponseStatusEnum];


/**
 * Check if a given object implements the CheckoutSessionStatusResponse interface.
 */
export function instanceOfCheckoutSessionStatusResponse(value: object): value is CheckoutSessionStatusResponse {
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('checkoutSessionId' in value) || value['checkoutSessionId'] === undefined) return false;
    if (!('subscriptionId' in value) || value['subscriptionId'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('trialPeriodDays' in value) || value['trialPeriodDays'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CheckoutSessionStatusResponseFromJSON(json: any): CheckoutSessionStatusResponse {
    return CheckoutSessionStatusResponseFromJSONTyped(json, false);
}

export function CheckoutSessionStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutSessionStatusResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'user': json['user'],
        'domain': json['domain'] == null ? undefined : json['domain'],
        'checkoutSessionId': json['checkoutSessionId'],
        'subscriptionId': json['subscriptionId'],
        'url': json['url'],
        'trialPeriodDays': json['trialPeriodDays'],
        'clientSecret': json['clientSecret'] == null ? undefined : json['clientSecret'],
        'status': json['status'],
    };
}

  export function CheckoutSessionStatusResponseToJSON(json: any): CheckoutSessionStatusResponse {
      return CheckoutSessionStatusResponseToJSONTyped(json, false);
  }

  export function CheckoutSessionStatusResponseToJSONTyped(value?: CheckoutSessionStatusResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user': value['user'],
        'domain': value['domain'],
        'checkoutSessionId': value['checkoutSessionId'],
        'subscriptionId': value['subscriptionId'],
        'url': value['url'],
        'trialPeriodDays': value['trialPeriodDays'],
        'clientSecret': value['clientSecret'],
        'status': value['status'],
    };
}

