import React from "react";

export const withFormReset = function <P>(
  FormComponent: React.ComponentType<P & { reset: () => void }>,
): React.FC<P> {
  return (props: P) => {
    const [key, setKey] = React.useState(Date.now());
    const handleReset = () => {
      setTimeout(() => {
        setKey(Date.now());
      }, 0);
    };
    return <FormComponent key={key} {...props} reset={handleReset} />;
  };
};
