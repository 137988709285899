import React from "react";
import { matchPath } from "react-router";

export const useInitialPath = () => {
  const [initialPath, setInitialPath] = React.useState<string | null>(
    window.location.pathname + window.location.search,
  );
  return React.useMemo(
    () => ({
      initialPath: initialPath,
      clearInitialPath: () => setInitialPath(null),
    }),
    [initialPath],
  );
};

export const InitialPathContext = React.createContext<
  ReturnType<typeof useInitialPath>
>(null as any);

export const usePopInitialPath = (routePath: string, isReady = true) => {
  const { initialPath, clearInitialPath } =
    React.useContext(InitialPathContext);
  const match = matchPath(initialPath || "", {
    path: routePath,
  });

  React.useEffect(() => {
    if (isReady && match) {
      clearInitialPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, routePath]);
  return isReady && match ? initialPath : null;
};
