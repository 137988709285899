/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Condition } from './Condition';
import {
    ConditionFromJSON,
    ConditionFromJSONTyped,
    ConditionToJSON,
    ConditionToJSONTyped,
} from './Condition';
import type { ButtonOptions } from './ButtonOptions';
import {
    ButtonOptionsFromJSON,
    ButtonOptionsFromJSONTyped,
    ButtonOptionsToJSON,
    ButtonOptionsToJSONTyped,
} from './ButtonOptions';
import type { Action } from './Action';
import {
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
    ActionToJSONTyped,
} from './Action';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserToJSONTyped,
} from './User';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
    DomainToJSONTyped,
} from './Domain';
import type { MultiButtonOptions } from './MultiButtonOptions';
import {
    MultiButtonOptionsFromJSON,
    MultiButtonOptionsFromJSONTyped,
    MultiButtonOptionsToJSON,
    MultiButtonOptionsToJSONTyped,
} from './MultiButtonOptions';

/**
 * 
 * @export
 * @interface Button
 */
export interface Button {
    /**
     * 
     * @type {string}
     * @memberof Button
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Button
     */
    user: User;
    /**
     * 
     * @type {Domain}
     * @memberof Button
     */
    domain: Domain;
    /**
     * 
     * @type {boolean}
     * @memberof Button
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Button
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Button
     */
    type: ButtonTypeEnum;
    /**
     * 
     * @type {ButtonOptions}
     * @memberof Button
     */
    options: ButtonOptions;
    /**
     * 
     * @type {MultiButtonOptions}
     * @memberof Button
     */
    multiButtonOptions?: MultiButtonOptions;
    /**
     * 
     * @type {Array<Action>}
     * @memberof Button
     */
    actions: Array<Action>;
    /**
     * 
     * @type {Array<Condition>}
     * @memberof Button
     */
    conditions: Array<Condition>;
}


/**
 * @export
 */
export const ButtonTypeEnum = {
    Single: 'SINGLE',
    Multi: 'MULTI',
    Full: 'FULL',
    Dots: 'DOTS'
} as const;
export type ButtonTypeEnum = typeof ButtonTypeEnum[keyof typeof ButtonTypeEnum];


/**
 * Check if a given object implements the Button interface.
 */
export function instanceOfButton(value: object): value is Button {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('actions' in value) || value['actions'] === undefined) return false;
    if (!('conditions' in value) || value['conditions'] === undefined) return false;
    return true;
}

export function ButtonFromJSON(json: any): Button {
    return ButtonFromJSONTyped(json, false);
}

export function ButtonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Button {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': UserFromJSON(json['user']),
        'domain': DomainFromJSON(json['domain']),
        'active': json['active'],
        'name': json['name'],
        'type': json['type'],
        'options': ButtonOptionsFromJSON(json['options']),
        'multiButtonOptions': json['multiButtonOptions'] == null ? undefined : MultiButtonOptionsFromJSON(json['multiButtonOptions']),
        'actions': ((json['actions'] as Array<any>).map(ActionFromJSON)),
        'conditions': ((json['conditions'] as Array<any>).map(ConditionFromJSON)),
    };
}

  export function ButtonToJSON(json: any): Button {
      return ButtonToJSONTyped(json, false);
  }

  export function ButtonToJSONTyped(value?: Button | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'user': UserToJSON(value['user']),
        'domain': DomainToJSON(value['domain']),
        'active': value['active'],
        'name': value['name'],
        'type': value['type'],
        'options': ButtonOptionsToJSON(value['options']),
        'multiButtonOptions': MultiButtonOptionsToJSON(value['multiButtonOptions']),
        'actions': ((value['actions'] as Array<any>).map(ActionToJSON)),
        'conditions': ((value['conditions'] as Array<any>).map(ConditionToJSON)),
    };
}

