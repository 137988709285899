import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { ExtendedFormState, ProFeature } from "../../common";
import { ActionType } from "../buttonModels";
import { DomainContext } from "../../domain";
import Box from "@mui/material/Box";
import { Action, Button } from "../../lib/cnb/api/public";

/**
 * Converts time string "hh:mm" to the number of minutes,
 * to be used in a Slider
 */
const stringToMinutes = (value: string | undefined) =>
  (value ?? "00")
    .split(":")
    .map((s) => parseInt(s))
    .reduce((h, m) => h * 60 + m, 0);

/**
 * Converts number of minutes to time string "hh:mm"
 */
const minutesToString = (value: number) =>
  [Math.floor(value / 60), value % 60]
    .map((n) => n.toString().padStart(2, "0"))
    .join(":");

/**
 * Number of minutes for "23:59"
 */
const MAX_MINUTES = 24 * 60 - 1;

const useStyles = makeStyles()((theme) => ({
  grow: {
    flexGrow: 1,
  },
  expandSwitch: {
    marginTop: -12,
    marginBottom: -12,
  },
  formGroup: {
    justifyContent: "space-between",
  },
  fullSize: {
    flexBasis: "100%",
  },
  dayList: {
    display: "flex",
    flexWrap: "nowrap",
    margin: theme.spacing(1, -0.5),
  },
  day: {
    borderRadius: theme.shape.borderRadius,
    padding: 2,
    margin: 2,
    minWidth: "1.8em",
    fontSize: "1rem",
    "&.Mui-checked": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  hoursLabel: {
    margin: theme.spacing(2, 0, 0.5),
  },
  proFeature: {
    marginTop: theme.spacing(1),
  },
}));

const Scheduling: React.FC<{
  formal: ExtendedFormState<Button | Action>;
  pathPrefix?: string;
}> = ({ formal, pathPrefix = "" }) => {
  const { classes, cx } = useStyles();
  const { currentDomain } = React.useContext(DomainContext);

  const panelOpen = !formal.getValue(`${pathPrefix}schedule.showAlways`);
  const handleOpenPanel = (_: any, open: boolean) => {
    formal.setValue(`${pathPrefix}schedule.showAlways`, !open);
  };

  const schedule = formal.getValue<Action["schedule"]>(`${pathPrefix}schedule`);
  const hours = [schedule?.start, schedule?.stop].map(stringToMinutes);
  const handleHoursChange = (_: any, minutes: number | number[]) => {
    const [start, stop] = (minutes as number[]).map(minutesToString);
    formal.setValue(`${pathPrefix}schedule`, { start, stop });
  };
  const afterHours = formal.getValue(`${pathPrefix}schedule.outsideHours`);

  if (currentDomain?.type === "STARTER") {
    return (
      <Box className={classes.proFeature}>
        <ProFeature featureName={"Scheduling is"} />
      </Box>
    );
  }

  return (
    <Accordion expanded={panelOpen} onChange={handleOpenPanel}>
      <AccordionSummary>
        <Typography className={classes.grow}>Always on</Typography>
        <Switch
          checked={!panelOpen}
          edge="end"
          color="primary"
          className={classes.expandSwitch}
        />
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup row className={classes.formGroup}>
          <div className={cx(classes.dayList, classes.fullSize)}>
            {ActionType.DAY_NAMES.map((name, index) => (
              <Checkbox
                key={index}
                icon={<span>{name}</span>}
                checkedIcon={<span>{name}</span>}
                className={classes.day}
                {...formal.getCheckboxProps(
                  `${pathPrefix}schedule.daysOfWeek[${index}]`,
                )}
              />
            ))}
          </div>
          <Typography className={classes.hoursLabel}>
            {!afterHours
              ? `From ${schedule?.start} till ${schedule?.stop}`
              : `Before ${schedule?.start} and after ${schedule?.stop}`}
          </Typography>
          <Slider
            value={hours}
            onChange={handleHoursChange}
            track={!afterHours ? "normal" : "inverted"}
            max={MAX_MINUTES}
            step={5}
          />
          <FormControlLabel
            control={
              <Switch
                {...formal.getCheckboxProps(
                  `${pathPrefix}schedule.outsideHours`,
                )}
                color="primary"
              />
            }
            label="Show button outside these hours"
            className={classes.fullSize}
          />
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default Scheduling;
