/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Domain } from './Domain';
import {
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
    DomainToJSONTyped,
} from './Domain';

/**
 * 
 * @export
 * @interface DomainDeleteResponse
 */
export interface DomainDeleteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DomainDeleteResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainDeleteResponse
     */
    id: string;
    /**
     * 
     * @type {Domain}
     * @memberof DomainDeleteResponse
     */
    object: Domain;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainDeleteResponse
     */
    messages: Array<string>;
}

/**
 * Check if a given object implements the DomainDeleteResponse interface.
 */
export function instanceOfDomainDeleteResponse(value: object): value is DomainDeleteResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('object' in value) || value['object'] === undefined) return false;
    if (!('messages' in value) || value['messages'] === undefined) return false;
    return true;
}

export function DomainDeleteResponseFromJSON(json: any): DomainDeleteResponse {
    return DomainDeleteResponseFromJSONTyped(json, false);
}

export function DomainDeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainDeleteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'id': json['id'],
        'object': DomainFromJSON(json['object']),
        'messages': json['messages'],
    };
}

  export function DomainDeleteResponseToJSON(json: any): DomainDeleteResponse {
      return DomainDeleteResponseToJSONTyped(json, false);
  }

  export function DomainDeleteResponseToJSONTyped(value?: DomainDeleteResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'success': value['success'],
        'id': value['id'],
        'object': DomainToJSON(value['object']),
        'messages': value['messages'],
    };
}

