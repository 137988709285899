import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import React from "react";

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
    border: `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
    "&:hover": {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity,
      ),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const DeleteButton: React.FC<{
  targetName: string;
  onClick: () => void;
}> = ({ targetName, onClick }) => {
  const { classes } = useStyles();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const handleDelete = () => setConfirmOpen(true);
  const handleCancel = (_: any, reason?: string) => {
    if (reason === "backdropClick") return;
    setConfirmOpen(false);
  };
  const handleOk = () => {
    setConfirmOpen(false);
    onClick();
  };

  return (
    <>
      <Divider />
      <Button
        variant="outlined"
        fullWidth
        className={classes.button}
        onClick={handleDelete}
      >
        Delete {targetName}
      </Button>
      <Dialog open={confirmOpen} onClose={handleCancel}>
        <DialogContent>
          Are you sure you want to completely delete this {targetName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteButton;
