/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordResetSuccessResponse
 */
export interface PasswordResetSuccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordResetSuccessResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetSuccessResponse
     */
    message: string;
}

/**
 * Check if a given object implements the PasswordResetSuccessResponse interface.
 */
export function instanceOfPasswordResetSuccessResponse(value: object): value is PasswordResetSuccessResponse {
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function PasswordResetSuccessResponseFromJSON(json: any): PasswordResetSuccessResponse {
    return PasswordResetSuccessResponseFromJSONTyped(json, false);
}

export function PasswordResetSuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetSuccessResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'message': json['message'],
    };
}

  export function PasswordResetSuccessResponseToJSON(json: any): PasswordResetSuccessResponse {
      return PasswordResetSuccessResponseToJSONTyped(json, false);
  }

  export function PasswordResetSuccessResponseToJSONTyped(value?: PasswordResetSuccessResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'success': value['success'],
        'message': value['message'],
    };
}

