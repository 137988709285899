import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { SubscriptionPrice } from "./components";
import { DomainContext } from "./useDomain";
import { AuthContext } from "../auth";
import OutstandingPaymentText from "./OutstandingPaymentText";
import { Domain, StripePlan } from "../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  planColumns: {
    marginTop: theme.spacing(1),
    justifyContent: "center",

    "& > div > div": {
      padding: theme.spacing(1.5),
      borderColor: theme.palette.divider,
      borderStyle: "solid",
      borderWidth: 1,
      marginRight: -1,
      marginBottom: -1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "&:first-of-type": {
        flexDirection: "column",
      },

      "&:last-child": {
        padding: theme.spacing(2.5),
      },

      "& svg": {
        marginRight: theme.spacing(0.5),
      },
    },
  },
}));

const ToggleButton: React.FC<{
  value: StripePlan["currency"];
  selected: StripePlan["currency"];
  onClick: (newValue: StripePlan["currency"]) => void;
}> = ({ value, selected, onClick, ...props }) => (
  <Button
    {...props}
    variant={selected === value ? "contained" : "outlined"}
    onClick={() => onClick(value)}
  >
    {value.toUpperCase()}
  </Button>
);

const SubscriptionPlan: React.FC = () => {
  const { classes } = useStyles();
  const { currentUser } = React.useContext(AuthContext);
  const [currency, setCurrency] = React.useState<StripePlan["currency"]>(
    (currentUser?.stripeDetails?.currency?.toUpperCase() as StripePlan["currency"]) ??
      "EUR",
  );

  const [checkoutError, setCheckoutError] = React.useState<string | null>(null);
  const { stripePlans, redirectToCheckout, wpInfo } =
    React.useContext(DomainContext);
  const userCurrency = currentUser?.stripeDetails?.currency?.toUpperCase();

  const plans = stripePlans?.filter(
    (plan) =>
      userCurrency == null ||
      plan.currency === currentUser?.stripeDetails?.currency?.toUpperCase(),
  );
  const proAnnualPrice = plans?.find(
    (p) => p.interval === "yearly" && p.currency === currency,
  )?.price;
  const proMonthlyPrice = plans?.find(
    (p) => p.interval === "monthly" && p.currency === currency,
  )?.price;
  const proAnnualTrialDays =
    plans?.find((p) => p.interval === "yearly" && p.currency === currency)
      ?.trialPeriodDays || 0;
  const proMonthlyTrialDays =
    plans?.find((p) => p.interval === "monthly" && p.currency === currency)
      ?.trialPeriodDays || 0;
  const handleCheckout = (interval: Domain["interval"]) => () => {
    redirectToCheckout(interval, currency).catch((e) => {
      setCheckoutError(e.message || "");
    });
  };

  if (wpInfo?.hasOutstandingInvoice()) {
    return <OutstandingPaymentText />;
  }
  return (
    <Grid container alignItems="center" direction="column">
      <Typography variant="h3" align="center" paragraph>
        Upgrade to unlock
      </Typography>
      <Typography variant="subtitle1" align="center" paragraph>
        + Up to 100 buttons
        <br />
        + Multiple buttons per page
        <br />
        + Button scheduler
        <br />
        + Multi-action buttons (Buttonbar &amp; Multibutton)
        <br />
        <br />
        + WhatsApp Chat window
        <br />
        + Content Windows (iframes)
        <br />
        + Intercom chat integration
        <br />
        + Tally forms integration
        <br />
        <br />
        + Change button icons & use custom images
        <br />
        + Appear after scrolling
        <br />
        + Button animations
        <br />
        + Advanced page rules
        <br />+ Country rules
      </Typography>
      {userCurrency == null && (
        <ButtonGroup color="primary">
          <ToggleButton value="EUR" selected={currency} onClick={setCurrency} />
          <ToggleButton value="USD" selected={currency} onClick={setCurrency} />
        </ButtonGroup>
      )}

      <Grid item container xs={12} className={classes.planColumns}>
        <Grid item container direction="column" xs={6}>
          <Grid item>
            <span>Yearly plan</span>
            <SubscriptionPrice
              value={(proAnnualPrice ?? 0) / 12}
              currency={currency}
            />
            <span>
              per month
              <br />
              (billed annually)
            </span>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckout("yearly")}
            >
              {proAnnualTrialDays > 0 ? (
                <>Start {proAnnualTrialDays}-day free trial</>
              ) : (
                <>Upgrade</>
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Grid item>
            <span>Monthly plan</span>
            <SubscriptionPrice
              value={proMonthlyPrice ?? 0}
              currency={currency}
            />
            <span>
              per month
              <br />
              (billed monthly)
            </span>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckout("monthly")}
            >
              {proMonthlyTrialDays > 0 ? (
                <>Start {proMonthlyTrialDays}-day free trial</>
              ) : (
                <>Upgrade</>
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {checkoutError !== null && (
        <FormHelperText error>
          {checkoutError || "Server error: something went wrong."}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default SubscriptionPlan;
