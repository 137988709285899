import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { ExpansionPanel } from "../../../common";
import { ActionType } from "../../buttonModels";
import { ActionPropsProps } from "../ActionProps";
import ColorPicker from "../ColorPicker";

const useStyles = makeStyles()({
  modalHeight: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > *:nth-child(2n+1)": {
      flexBasis: "80%",
    },
  },
});

interface IframeSettingsProps extends ActionPropsProps {
  includeWidthAndHeight?: boolean;
}

const IframeSettings: React.FC<IframeSettingsProps> = ({
  formal,
  pathPrefix,
  includeWidthAndHeight = true,
}) => {
  const { classes } = useStyles();
  // For the modal-height, split the value into the slider and select part
  const parseModalHeight = () =>
    (
      formal.getValue<string | undefined>(
        `${pathPrefix}properties.modal-height`,
      ) ?? ""
    ).match(ActionType.MODAL_HEIGHT_REGEXP) ?? [];

  const [modalHeightValue, setModalHeightValue] = React.useState(
    () => Number(parseModalHeight()[1]) || 250,
  );
  const [modalHeightUnit, setModalHeightUnit] = React.useState(
    () =>
      (parseModalHeight()[2] ||
        "px") as (typeof ActionType.MODAL_HEIGHT_UNIT_VALUES)[number],
  );

  React.useEffect(() => {
    if (modalHeightUnit === "px" && modalHeightValue < 250) {
      setModalHeightValue(250);
    } else if (modalHeightUnit === "vh" && modalHeightValue > 100) {
      setModalHeightValue(100);
    } else {
      formal.setValue(
        `${pathPrefix}properties.modal-height`,
        modalHeightValue + modalHeightUnit,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHeightValue, modalHeightUnit]);

  return (
    <ExpansionPanel title={"Window settings"}>
      <FormGroup>
        <TextField
          label="Window title"
          {...formal.getTextFieldProps(`${pathPrefix}properties.iframe-title`)}
        />
        {includeWidthAndHeight && (
          <FormControl className={classes.modalHeight}>
            <Typography>Window height</Typography>
            <Typography>{modalHeightValue + modalHeightUnit}</Typography>
            <Slider
              marks={modalHeightUnit === "vh"}
              value={modalHeightValue}
              onChange={(_, value) => setModalHeightValue(value as number)}
              valueLabelDisplay="on"
              min={modalHeightUnit === "px" ? 250 : 20}
              max={modalHeightUnit === "px" ? 1500 : 100}
              step={5}
            />
            <Select
              value={modalHeightUnit}
              native={true}
              label="Unit"
              onChange={(e) => setModalHeightUnit(e.target.value as any)}
            >
              {ActionType.MODAL_HEIGHT_UNIT_VALUES.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        {includeWidthAndHeight && (
          <TextField
            label="Window width"
            select
            SelectProps={{
              native: true,
            }}
            {...formal.getTextFieldProps(`${pathPrefix}properties.modal-width`)}
          >
            {Object.entries(ActionType.MODAL_WIDTH_OPTIONS).map(
              ([value, name]) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ),
            )}
          </TextField>
        )}
        <ColorPicker
          label="Header background"
          defaultValue={"#009900"}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.modal-header-background-color`,
          )}
        />
        <ColorPicker
          label="Header text"
          defaultValue={"#000000"}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.modal-header-text-color`,
          )}
        />
      </FormGroup>
    </ExpansionPanel>
  );
};

export default IframeSettings;
