import React from "react";
import { ActionPropsProps } from "../ActionProps";
import TextField from "@mui/material/TextField";
import { ExpansionPanel } from "../../../common";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const ChatSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  const isRequireLegalNotice =
    formal.getValue(`${pathPrefix}properties.chat-legal-enabled`) === true ||
    formal.getValue(`${pathPrefix}properties.chat-legal-enabled`) === "true";

  // In case there is not template yet, we set a default one
  if (
    isRequireLegalNotice &&
    formal.getValue(`${pathPrefix}properties.chat-legal-notice`) === undefined
  ) {
    formal.setValue(
      `${pathPrefix}properties.chat-legal-notice`,
      "I agree to the {link1}, {link2} and {link3} of COMPANY.",
    );
  }
  return (
    <>
      <ExpansionPanel title={"Chat settings"}>
        <FormGroup>
          <TextField
            label="Welcome message"
            multiline
            {...formal.getTextFieldProps(
              `${pathPrefix}properties.chatmodal-welcome-message`,
            )}
          />
          <TextField
            label="Placeholder message"
            {...formal.getTextFieldProps(
              `${pathPrefix}properties.chatmodal-placeholder-message`,
            )}
          />
          <TextField
            label="Agent response time message"
            {...formal.getTextFieldProps(
              `${pathPrefix}properties.chat-agent-message`,
            )}
          />
        </FormGroup>
      </ExpansionPanel>
      <ExpansionPanel title={"Legal notice"}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                {...formal.getCheckboxProps(
                  `${pathPrefix}properties.chat-legal-enabled`,
                )}
              />
            }
            label="Require legal notice"
          />
          {isRequireLegalNotice && (
            <>
              <TextField
                label="Template"
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-notice`,
                )}
              />
              <Typography>{"{link1}"}</Typography>
              <TextField
                label="E.g. Privacy policy"
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-link1-text`,
                )}
              />
              <TextField
                label="https://website.com/privacy.html"
                type={"url"}
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-link1-link`,
                )}
                helperText={"Internet address"}
              />
              <Typography>{"{link2}"}</Typography>
              <TextField
                label="E.g. Terms"
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-link2-text`,
                )}
              />
              <TextField
                label="https://website.com/terms.html"
                type={"url"}
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-link2-link`,
                )}
                helperText={"Internet address"}
              />
              <Typography>{"{link3}"}</Typography>
              <TextField
                label="E.g. GDPR statement"
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-link3-text`,
                )}
              />
              <TextField
                label="https://website.com/gdpr.html"
                type={"url"}
                {...formal.getTextFieldProps(
                  `${pathPrefix}properties.chat-legal-link3-link`,
                )}
                helperText={"Internet address"}
              />
            </>
          )}
        </FormGroup>
      </ExpansionPanel>
    </>
  );
};

export default ChatSettings;
