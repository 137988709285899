/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Auth } from './Auth';
import {
    AuthFromJSON,
    AuthFromJSONTyped,
    AuthToJSON,
    AuthToJSONTyped,
} from './Auth';
import type { Session } from './Session';
import {
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
    SessionToJSONTyped,
} from './Session';

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {Session}
     * @memberof LoginResponse
     */
    session: Session;
    /**
     * 
     * @type {Auth}
     * @memberof LoginResponse
     */
    auth: Auth;
}

/**
 * Check if a given object implements the LoginResponse interface.
 */
export function instanceOfLoginResponse(value: object): value is LoginResponse {
    if (!('session' in value) || value['session'] === undefined) return false;
    if (!('auth' in value) || value['auth'] === undefined) return false;
    return true;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'session': SessionFromJSON(json['session']),
        'auth': AuthFromJSON(json['auth']),
    };
}

  export function LoginResponseToJSON(json: any): LoginResponse {
      return LoginResponseToJSONTyped(json, false);
  }

  export function LoginResponseToJSONTyped(value?: LoginResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'session': SessionToJSON(value['session']),
        'auth': AuthToJSON(value['auth']),
    };
}

