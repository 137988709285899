import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { ActionType } from "../../buttonModels";

const SkypeSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  const isSkypeLinkType =
    formal.getValue(`${pathPrefix}properties.skype-link-type`) === "SKYPE" ||
    !formal.getValue(`${pathPrefix}properties.skype-link-type`);
  return (
    <ExpansionPanel title="Skype Settings">
      <FormGroup>
        <TextField
          label="Type"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.skype-link-type`,
          )}
        >
          {Object.entries(ActionType.SKYPE_LINK_TYPE).map(([value, name]) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </TextField>
        {isSkypeLinkType && (
          <TextField
            label="Skype action"
            select
            SelectProps={{
              native: true,
            }}
            {...formal.getTextFieldProps(
              `${pathPrefix}properties.skype-param-type`,
            )}
          >
            {Object.entries(ActionType.SKYPE_PARAM_TYPE).map(
              ([value, name]) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ),
            )}
          </TextField>
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default SkypeSettings;
