/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Apikey
 */
export interface Apikey {
    /**
     * 
     * @type {string}
     * @memberof Apikey
     */
    id: string;
    /**
     * The user ID of the user who created this API.
     * @type {string}
     * @memberof Apikey
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof Apikey
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Apikey
     */
    lastUsed?: Date;
}

/**
 * Check if a given object implements the Apikey interface.
 */
export function instanceOfApikey(value: object): value is Apikey {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ApikeyFromJSON(json: any): Apikey {
    return ApikeyFromJSONTyped(json, false);
}

export function ApikeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Apikey {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': json['user'],
        'name': json['name'],
        'lastUsed': json['lastUsed'] == null ? undefined : (new Date(json['lastUsed'])),
    };
}

  export function ApikeyToJSON(json: any): Apikey {
      return ApikeyToJSONTyped(json, false);
  }

  export function ApikeyToJSONTyped(value?: Apikey | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'user': value['user'],
        'name': value['name'],
        'lastUsed': value['lastUsed'] == null ? undefined : ((value['lastUsed']).toISOString()),
    };
}

