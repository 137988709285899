import { Settings, User } from "../../lib/cnb/api/public";
import { settingsApi } from "../../cnb/apis";

export const api = {
  getSettings: async (user: User): Promise<Settings> => {
    return settingsApi.getSettingsJsonForUser({ userId: user.id });
  },
};

export const demoApi = {
  getSettings: async (user: User): Promise<Settings> => {
    return {
      userId: "demo",
      options: {
        cssLocation: "http://localhost:5000/css/main.css",
        jsLocation: "http://localhost:5000/js/main.js",
        staticRoot: "http://localhost:5000",
        userRoot: "http://localhost:5000",
        storageType: "GCS",
      },
    } as Settings;
  },
};
