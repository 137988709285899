import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { ExtendedFormState } from "../useExtendedForm";

const useStyles = makeStyles()((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
    "& > label + *": {
      marginTop: theme.spacing(2),
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface ReadonlyFieldProps {
  label?: React.ReactNode;
  staticValue?: string;
  className?: string;
  children?: React.ReactNode;
}

export const FormField: React.FC<ReadonlyFieldProps> = ({
  label,
  staticValue,
  className,
  children,
}) => {
  const { classes, cx } = useStyles();
  return (
    <FormControl className={cx(classes.field, className)}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      {staticValue ? (
        <Typography variant="body1" color="textSecondary">
          {staticValue}
        </Typography>
      ) : (
        children
      )}
    </FormControl>
  );
};

interface FormActionsProps {
  formal: ExtendedFormState<unknown>;
  className?: string;
}

export const FormActions: React.FC<FormActionsProps> = ({
  formal,
  className,
}) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.actions, className)}>
      <Button
        variant="contained"
        color="primary"
        {...formal.getSubmitButtonProps()}
        type="submit"
      >
        Save
      </Button>
      <Button
        variant="contained"
        {...formal.getResetButtonProps()}
        type="button"
      >
        Cancel
      </Button>
    </div>
  );
};
