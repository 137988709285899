/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.200-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Button } from './Button';
import {
    ButtonFromJSON,
    ButtonFromJSONTyped,
    ButtonToJSON,
    ButtonToJSONTyped,
} from './Button';
import type { ImportDomain } from './ImportDomain';
import {
    ImportDomainFromJSON,
    ImportDomainFromJSONTyped,
    ImportDomainToJSON,
    ImportDomainToJSONTyped,
} from './ImportDomain';

/**
 * 
 * @export
 * @interface ExportButtonResponse
 */
export interface ExportButtonResponse {
    /**
     * 
     * @type {ImportDomain}
     * @memberof ExportButtonResponse
     */
    sourceDomain?: ImportDomain;
    /**
     * 
     * @type {Array<Button>}
     * @memberof ExportButtonResponse
     */
    buttons: Array<Button>;
}

/**
 * Check if a given object implements the ExportButtonResponse interface.
 */
export function instanceOfExportButtonResponse(value: object): value is ExportButtonResponse {
    if (!('buttons' in value) || value['buttons'] === undefined) return false;
    return true;
}

export function ExportButtonResponseFromJSON(json: any): ExportButtonResponse {
    return ExportButtonResponseFromJSONTyped(json, false);
}

export function ExportButtonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportButtonResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceDomain': json['sourceDomain'] == null ? undefined : ImportDomainFromJSON(json['sourceDomain']),
        'buttons': ((json['buttons'] as Array<any>).map(ButtonFromJSON)),
    };
}

  export function ExportButtonResponseToJSON(json: any): ExportButtonResponse {
      return ExportButtonResponseToJSONTyped(json, false);
  }

  export function ExportButtonResponseToJSONTyped(value?: ExportButtonResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sourceDomain': ImportDomainToJSON(value['sourceDomain']),
        'buttons': ((value['buttons'] as Array<any>).map(ButtonToJSON)),
    };
}

