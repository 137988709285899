import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import { ExpansionPanel } from "../../../common";
import { ActionPropsProps } from "../ActionProps";
import { ActionType } from "../../buttonModels";

const LineSettings: React.FC<ActionPropsProps> = ({ formal, pathPrefix }) => {
  const isMessageLinkType =
    formal.getValue(`${pathPrefix}properties.line-link-type`) === "MESSAGE" ||
    !formal.getValue(`${pathPrefix}properties.line-link-type`);
  return (
    <ExpansionPanel title="Line Settings">
      <FormGroup>
        <TextField
          label="Type"
          select
          SelectProps={{
            native: true,
          }}
          {...formal.getTextFieldProps(
            `${pathPrefix}properties.line-link-type`,
          )}
        >
          {Object.entries(ActionType.LINE_LINK_TYPE).map(([value, name]) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </TextField>
        {isMessageLinkType && (
          <TextField
            label="Message"
            {...formal.getTextFieldProps(
              `${pathPrefix}properties.line-message`,
            )}
          />
        )}
      </FormGroup>
    </ExpansionPanel>
  );
};

export default LineSettings;
