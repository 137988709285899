import React from "react";
import Icon from "@mui/material/Icon";
import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { darken } from "@mui/system";
import { makeStyles } from "tss-react/mui";
import { DomainContext } from "../../domain";
import { ExtendedFormState, ProFeature } from "../../common";
import { ActionIconTypeSelection } from "../buttonModels";
import { Action, Button } from "../../lib/cnb/api/public";

const useStyles = makeStyles()((theme) => ({
  iconGroup: {
    marginBottom: theme.spacing(2),
  },
  iconText: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: darken(theme.palette.grey[300], 0.1),
    },
    "&.Mui-checked": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

type IconTextChooserProps = {
  iconTexts: ActionIconTypeSelection;
  name: string;
  type: string;
  formal: ExtendedFormState<Button | Action>;
};

const getFontName = (iconType: Action["iconType"]) => {
  if (iconType === "FONT_MATERIAL") return "cnb-font-material-icons";
  return "cnb-icons";
};

const IconTextChooser: React.FC<RadioGroupProps & IconTextChooserProps> = ({
  iconTexts = [],
  className,
  name,
  type,
  formal,
}) => {
  const { classes, cx } = useStyles();
  const { currentDomain } = React.useContext(DomainContext);

  const [iconTypeValue, setIconTypeValue] = React.useState<string>(
    formal.getValue(type),
  );

  React.useEffect(() => {
    formal.setValue(type, iconTypeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconTypeValue]);

  // On "STARTER", only the default (first) icon is allowed
  if (currentDomain?.type === "STARTER") {
    return (
      <ProFeature
        featureName={"Icon selector and custom images are"}
        plural={true}
      />
    );
  }

  return (
    <RadioGroup
      row
      className={cx(className, classes.iconGroup)}
      {...formal.getRadioGroupProps(name)}
    >
      {iconTexts.map(({ iconText, iconType }) => (
        <Radio
          key={`${iconText}-${iconType}`}
          value={iconText}
          checked={
            iconText === formal.getValue(name) && iconType === iconTypeValue
          }
          icon={<Icon baseClassName={getFontName(iconType)}>{iconText}</Icon>}
          onClick={() => setIconTypeValue(iconType)}
          checkedIcon={
            <Icon baseClassName={getFontName(iconType)}>{iconText}</Icon>
          }
          className={classes.iconText}
        />
      ))}
    </RadioGroup>
  );
};

export default IconTextChooser;
