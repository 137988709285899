import React from "react";
import { ExtendedFormState, FormField } from "../../common";
import Switch from "@mui/material/Switch";
import UpgradeButton from "../../button/components/UpgradeButton";
import { DomainType } from "../domainModels";
import { DomainContext } from "../useDomain";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Domain, DomainStatusEnum } from "../../lib/cnb/api/public";

interface RenewalSettingsProps {
  formal: ExtendedFormState<unknown>;
  currentDomain: Domain;
}

const RenewalSettings: React.FC<RenewalSettingsProps> = ({
  currentDomain,
  formal,
}) => {
  const { paymentResult } = React.useContext(DomainContext);

  return (
    <>
      {currentDomain.type === "PRO" && (
        <DomainStatus status={currentDomain.status} />
      )}

      {currentDomain.type === "PRO" && currentDomain.expires && (
        <>
          <Expires currentDomain={currentDomain} />
          <RenewToggle currentDomain={currentDomain} formal={formal} />
        </>
      )}

      {currentDomain.type !== "PRO" && paymentResult.status !== "pending" && (
        <UpgradeDomain />
      )}
    </>
  );
};

const DomainStatus = ({ status }: { status: DomainStatusEnum }) => {
  return (
    <FormField label="Domain status">
      <Typography variant="body1" color="textSecondary">
        {status && DomainType.DOMAIN_STATUS[status]}
      </Typography>
    </FormField>
  );
};

const Expires = ({ currentDomain }: { currentDomain: Domain }) => {
  const currentLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  const dateFormat = new Intl.DateTimeFormat([currentLocale, "en-GB"]);
  return (
    <FormField label="Renewal">
      <Typography variant="body1" color="textSecondary">
        Your subscription
        {currentDomain.status === "ACTIVE" && currentDomain.renew && " renews "}
        {currentDomain.status === "ACTIVE" &&
          !currentDomain.renew &&
          " expires "}
        {currentDomain.status === "TRIALING" &&
          currentDomain.renew &&
          " starts "}
        {currentDomain.status === "TRIALING" &&
          !currentDomain.renew &&
          " will revert to the Starter plan on "}
        {dateFormat.format(currentDomain.expires)}
      </Typography>
    </FormField>
  );
};

const RenewToggle = ({
  currentDomain,
  formal,
}: {
  currentDomain: Domain;
  formal: ExtendedFormState<unknown>;
}) => {
  return (
    <FormField>
      <FormControlLabel
        control={
          <Switch color="primary" {...formal.getCheckboxProps("renew")} />
        }
        label={
          currentDomain.status === "TRIALING"
            ? "Continue Pro after trial"
            : "Auto renew"
        }
      />
    </FormField>
  );
};

const UpgradeDomain = () => {
  return (
    <FormField>
      <UpgradeButton variant={"contained"} />
    </FormField>
  );
};
export default RenewalSettings;
