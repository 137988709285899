const routeDefinitions = {
  LOGIN: { path: "/login" },
  REGISTER: { path: "/register" },
  FORGOT_PASSWORD: { path: "/forgot-password" },
  RESET_PASSWORD: { path: "/reset-password" },
  DASHBOARD: { path: "/app" },
  DOMAIN_NEW: { path: "/app/domain/-new", title: "Add New Domain" },
  DOMAIN_EDIT: { path: "/app/domain/edit", title: "Domain Settings" },
  DOMAIN_PLAN: { path: "/app/domain/edit/upgrade", title: "Upgrade to PRO" },
  PROFILE: { path: "/app/profile", title: "Account Settings" },
  BUTTON_NEW: { path: "/app/button/-new", title: "Create New Button" },
  BUTTON_EDIT: {
    path: "/app/button/:bid([^-][^/#?]*?)",
    title: "Button Settings",
  },
  ACTION_NEW: {
    path: "/app/button/:bid/action/-new",
    title: "Create Action",
  },
  ACTION_EDIT: {
    path: "/app/button/:bid/action/:aid([^-][^/#?]*?)",
    title: "Action Settings",
  },
};
export default routeDefinitions;
