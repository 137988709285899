import FormGroup from "@mui/material/FormGroup";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { FormActions, useExtendedForm, ExtendedFormState } from "../common";
import { DomainContext } from "../domain";
import { ActionType } from "./buttonModels";
import {
  ActionPresentation,
  DeleteButton,
  Scheduling,
  ActionProps,
} from "./components";
import { Action, type ActionSchedule, Button } from "../lib/cnb/api/public";

export const actionSchema = yup
  .object<Action>()
  .shape({
    actionType: yup.mixed().required() as yup.MixedSchema<Action["actionType"]>,
    actionValue: yup
      .string()
      .when("actionType", (type: Action["actionType"], s: yup.StringSchema) =>
        s.required(ActionType.VALUE_VALIDATION_MESSAGE[type]),
      )
      .defined(),
    backgroundColor: yup.string().required("color value is required"),
    iconColor: yup.string().required("color value is required"),
    iconEnabled: yup.boolean().defined(),
    labelText: yup
      .string()
      .when(
        "iconEnabled",
        (iconEnabled: boolean | null, s: yup.StringSchema) =>
          iconEnabled === false
            ? s.required(
                "Please use an action label (or select 'Show icon' via Presentation Settings)",
              )
            : s.notRequired(),
      )
      .defined(),
    schedule: yup
      .object<ActionSchedule>()
      .shape({
        showAlways: yup.boolean().defined(),
        start: yup
          .string()
          .when("showAlways", (show: boolean, s: yup.StringSchema) =>
            show ? s.notRequired() : s.required(),
          ),
        stop: yup
          .string()
          .when("showAlways", (show: boolean, s: yup.StringSchema) =>
            show ? s.notRequired() : s.required(),
          ),
      })
      .required(),
  })
  .defined();

const schema = actionSchema.concat(
  yup
    .object<Action>()
    .shape({
      backgroundColor: yup.string().required(),
      iconColor: yup.string().required(),
    })
    .defined(),
);

const ActionSettings: React.FC<{
  buttonFormal: ExtendedFormState<Button>;
}> = ({ buttonFormal }) => {
  const { aid } = useParams<{ aid: string }>();
  const history = useHistory();
  const { currentDomain } = React.useContext(DomainContext);
  const actionIndex = aid ? Number(aid) : buttonFormal.values.actions.length;
  const formal = useExtendedForm({
    initialValues: buttonFormal.values.actions[actionIndex] || {
      ...ActionType.INITIAL,
      schedule: {
        ...ActionType.INITIAL.schedule,
        timezone: currentDomain?.timezone,
      },
    },
    schema,
    onSubmit: (values) => {
      const actions = buttonFormal.values.actions.slice();
      actions[actionIndex] = { ...actions[actionIndex], ...values };
      buttonFormal.change("actions", actions);
      history.goBack();
    },
    onReset: () => history.goBack(),
  });
  const handleDelete = (index: number) => {
    const actions = buttonFormal.values.actions.slice();
    actions.splice(index, 1);
    buttonFormal.change("actions", actions);
    history.goBack();
  };

  return (
    <form {...formal.getFormProps()}>
      <FormGroup>
        <ActionProps formal={formal} buttonType={buttonFormal.values.type} />
        <ActionPresentation
          formal={formal}
          buttonType={buttonFormal.values.type}
        />
        <Scheduling formal={formal} />
        <FormActions formal={formal} />
        {actionIndex < buttonFormal.values.actions.length && (
          <DeleteButton
            targetName="action"
            onClick={() => handleDelete(actionIndex)}
          />
        )}
      </FormGroup>
    </form>
  );
};

export default ActionSettings;
