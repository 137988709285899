import { makeStyles } from "tss-react/mui";
import React from "react";
import { CurrencyType } from "../domainModels";
import { StripePlan } from "../../lib/cnb/api/public";

const useStyles = makeStyles()({
  root: {
    display: "inline-flex",
    fontWeight: "bold",
    paddingBottom: "0.5em",
  },
  currency: {
    marginTop: "0.8em",
  },
  integer: {
    fontSize: "4em",
    lineHeight: 1,
  },
  fraction: {
    fontSize: "2em",
    marginLeft: "-0.3em",
  },
});

const SubscriptionPrice: React.FC<{
  value: number;
  currency: StripePlan["currency"];
}> = ({ value, currency }) => {
  const { classes } = useStyles();
  const priceParts = CurrencyType.NUMBER_FORMAT[currency].formatToParts(value);
  const currencyPart = priceParts.find((p) => p.type === "currency")?.value;
  const integerPart = priceParts
    .filter((p) => !["currency", "literal", "fraction"].includes(p.type))
    .map((p) => p.value)
    .join("");
  const fractionPart = priceParts.find((p) => p.type === "fraction")?.value;

  return (
    <span className={classes.root}>
      <span className={classes.currency}>{currencyPart}</span>
      <span className={classes.integer}>{integerPart}</span>
      <span className={classes.fraction}>{fractionPart}</span>
    </span>
  );
};

export default SubscriptionPrice;
